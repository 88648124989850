import './HomeMain.css'


import Plus from '../../../Assets/HomeAssets/Plus-HeaderMain.png'
import Circle from '../../../Assets/HomeAssets/Circle-HeaderMain.png'
import MineForFuture from '../../../Assets/HomeAssets/MineForFuture-HeaderMain.png'

import EarnForFuture from '../../../Assets/HomeAssets/EarnForFuture-HeaderMain.png'
import SaveForFuture from '../../../Assets/HomeAssets/SaveForFuture-HeaderMain.png'
import HandsImage from '../../../Assets/HomeAssets/HandsImage-HeaderMain.png'
import { useEffect } from 'react'

import AOS from 'aos'
import "aos/dist/aos.css";

const HomeMain=()=>{

    useEffect(()=>{
        AOS.init({
            once: true,
            // disable: "phone",
            duration: 1000,
            easing: "ease-out-cubic",
        });
    },[])

    return(
        <div className='home-main' >


            <div className='home-main-a1 home-main-a1-big' >
                <img 
                    src={Plus} 
                    alt='Plus' 
                    className='plus-image plus-image-large' 
                    loading='lazy'
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="2000"
                />
                <img 
                    src={MineForFuture} 
                    alt='MineForFuture' 
                    className='mineForFuture-image' 
                    loading='lazy'
                    data-aos="fade-right"
                    data-aos-easing="linear"
                    data-aos-duration="2000"
                />
                <img 
                    src={Circle} 
                    alt='Circle' 
                    className='circle-image circle-image-large'  
                    loading='lazy'
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="2000"
                />
            </div>


            <div className='home-main-c1' data-aos="fade-down" data-aos-easing="linear" data-aos-duration="1500">
                <div className='home-main-c1-head'>
                    The Crypto, <span>of the Future</span>
                </div>
                <div className='home-main-c1-subHead' >
                    The Official site to Claim and swap your geoDB coins (BGEO) to BTC, ETH and BNB through Trust wallet or transfer in your bank or payPal
                </div>
            </div>


            <div className='home-main-a2'>
                <img 
                    src={Plus} 
                    alt='Plus' 
                    className='plus-image plus-image-small' 
                    loading='lazy'
                    data-aos="fade-down"
                    data-aos-easing="linear"
                    data-aos-duration="2000"
                />
                <img 
                    src={MineForFuture} 
                    alt='MineForFuture' 
                    className='mineForFuture-image mineForFuture-image-small' 
                    loading='lazy'
                    data-aos="fade-right"
                    data-aos-easing="linear"
                    data-aos-duration="2000"
                />
                <img 
                    src={EarnForFuture} 
                    alt='Earn For Future' 
                    className='card-style1' 
                    loading='lazy'
                    data-aos="fade-right"
                    data-aos-easing="linear"
                    data-aos-duration="2000"
                />
                <img 
                    src={SaveForFuture} 
                    alt='Save For Future' 
                    className='card-style1' 
                    loading='lazy'
                    data-aos="fade-left"
                    data-aos-easing="linear"
                    data-aos-duration="2000"
                />

                <img 
                    src={Circle} 
                    alt='Circle' 
                    className='circle-image circle-image-small '  
                    loading='lazy'
                    data-aos="fade-up"
                    data-aos-easing="linear"
                    data-aos-duration="2000"
                />
            </div>


            <div className='home-main-a3' >
                <img 
                    src={HandsImage} 
                    alt='Hands' 
                    loading='lazy'
                    data-aos="fade-right"
                    data-aos-duration="2000"
                    data-aos-easing="ease-in-sine"
                />
            </div>


        </div>
    )
}
export default HomeMain;