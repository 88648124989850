import {Outlet, Navigate} from 'react-router-dom'
import isCardAnyCardActiveStatus from './CheckCardActiveStatus';

const ProtectedRoutesForActivatedCard=()=>{
    const auth={'token': false}
    isCardAnyCardActiveStatus ? auth.token=true : auth.token=false;

    return(
        auth.token ? <Outlet/> : <Navigate to='/user/activateDebitCard' />
    )
}

export default ProtectedRoutesForActivatedCard;