// TradingViewWidget.jsx
import React, { useEffect, useRef } from 'react';

function TradingViewWidget(props) {
  const container = useRef();

  useEffect(
    () => {
      if (!container.current.querySelector('script[src="https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js"]')) {
        const script = document.createElement("script");
        script.src = "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
        script.type = "text/javascript";
        script.async = true;
        script.innerHTML = `
          {
            "width": "100%",
            "height": "410",
            "symbol": "${props.chartSymbol}",
            "interval": "D",
            "timezone": "Etc/UTC",
            "theme": "light",
            "style": "1",
            "locale": "in",
            "enable_publishing": false,
            "support_host": "https://www.tradingview.com"
          }`;
        container.current.appendChild(script);
      }

      
    },
    [props.chartSymbol]
  );

  return (
    <div className="tradingview-widget-container" ref={container}>
      <div className="tradingview-widget-container__widget"></div>
      {/* <div className="tradingview-widget-copyright"><a href="https://in.tradingview.com/" rel="noopener nofollow" target="_blank"><span className="blue-text">Track all markets on TradingView</span></a></div> */}
    </div>
  );
}

export default TradingViewWidget;
