import Card3 from "../../Components/Card3/Card3";
import {useParams} from 'react-router-dom'

const PlanComponent=()=>{

    const {planName} = useParams()


    return(
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            margin: '50px -50px',
            // marginLeft:'-50px',
            // marginRight:'-50px'
        }}>
            <Card3 head={planName} />
        </div>
    )
}
export default PlanComponent;