import './Card3.css'
import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import countries from '../../Utils/AllCountries';
import { MenuItem } from '@mui/material';

import {useNavigate} from 'react-router-dom'

const Card3=(props)=>{
    const navigate=useNavigate();
    return(
        <div className='card3-main' >
            <h2>{props.head}</h2>
            <form>
                <TextField 
                    id="outlined-basic" 
                    label="Name" 
                    variant="outlined" 
                    autoComplete='name'
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '100%'
                    }}
                />
                <br/>
                <TextField 
                    id="outlined-basic" 
                    label="Email Address" 
                    variant="outlined" 
                    type='email'
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '100%'
                    }}
                />
                <br/>
                <TextField 
                    id="outlined-basic" 
                    label="Address" 
                    variant="outlined"

                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '100%'
                    }}
                />
                <br/>
                <TextField 
                    id="outlined-basic" 
                    label="Zip Code" 
                    variant="outlined" 
                    type='number'
                    fullWidth
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '100%'
                    }}
                />
                <br/>
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Country"
                    defaultValue=""
                    autoComplete='country'
                    // helperText="Please select your currency"
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '100%'
                    }}
                >
                    {countries.map((option) => (
                        <MenuItem key={option.code} value={option.name}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
                <br/>
                <Button 
                    variant="contained" 
                    color="success"
                    onClick={()=>navigate('/user/paymentoption')}
                    style={{
                        padding: '15px',
                        fontSize: '16px'
                    }}

                >
                    Make Payment
                </Button>
                
            </form>
        </div>
    )
}
export default Card3;