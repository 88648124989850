import './Support.css'
import { useState } from 'react';
import { TextField, Button, CircularProgress } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'

import axios from 'axios'
import config from '../../config/default';

const Support = () => {


    const navigate=useNavigate();

    const [email, setEmail] = useState(JSON.parse(localStorage.getItem('data')).email)
    const [subject, setSubject]=useState()
    const [description, setDescription]=useState()
    const [supportAttachment, setSupportAttachment] = useState();
    const [submitButton, setSubmitButton]=useState('SUBMIT');


    const handleClick = async (e) => {
        e.preventDefault();
        setSubmitButton(<CircularProgress color="inherit" />);

        const data = new FormData();

        data.append('subject', subject);
        data.append('email', email);
        data.append('description', description);
        data.append('image', supportAttachment);



        await axios.post(`${config.server.link}/user/support`, data,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then((response) => {
                setSubmitButton("Submit")
                if (response.status === 200) {
                    Swal.fire({title: `${response.data.message}`, icon: "success"})
                        .then(() => {
                            navigate("/user/dashboard")
                        })

                } else if (response.status === 202) {
                    Swal.fire({title: `${response.data.message}`, icon: "error"})
                }
            })
    }




    return(
        <div className="login-main signup-main " >

            <h1 className='login-main-p1' >SUPPORT</h1>   
            <form className='login-main-p2' >
                <TextField
                    id="outlined-basic" 
                    disabled
                    label="Email" 
                    variant="outlined" 
                    value={email}
                    type='email'
                    onChange={(e)=>setEmail(e.target.value)}
                    autoComplete='email'
                    style={{
                        backgroundColor:'#F2F2F2',
                        color:'black',
                        width: '80%',
                        margin: '15px 0px',
                        zIndex: '0'
                    }}
                />
                <TextField
                    id="outlined-basic" 
                    label="Subject" 
                    variant="outlined" 
                    value={subject}
                    type='text'
                    onChange={(e)=>setSubject(e.target.value)}
                    autoComplete='email'
                    style={{
                        backgroundColor:'#F2F2F2',
                        color:'black',
                        width: '80%',
                        margin: '15px 0px',
                        zIndex: '0'
                    }}
                />
                
                <TextareaAutosize
                    label="Description" 
                    variant="outlined" 
                    placeholder='Description'
                    value={description}
                    onChange={(e)=>setDescription(e.target.value)}
                    style={{
                        backgroundColor:'#E3E3F6',
                        color: 'black',
                        width: '77%',
                        minWidth: '47.8%',
                        maxWidth: '80%',
                        minHeight:'50px',
                        margin: '15px 0px',
                        borderRadius: '5px',
                        borderColor: '#BABABA',
                        padding: '16.5px 14px',
                        fontFamily: 'Poppins'
                        
                    }}
                />
                <TextField
                    id="outlined-basic" 
                    variant="outlined" 
                    type='file'
                    name='image'
                    onChange={(e)=>setSupportAttachment(e.target.files[0])}
                    inputProps={{
                        accept: 'image/*'
                    }}
                    style={{
                        backgroundColor:'#F2F2F2',
                        color:'black',
                        width: '80%',
                        margin: '15px 0px'
                    }}
                />
 
                
            </form>
            <div className='login-main-p3' >
                <Button 
                        variant="contained" 
                        color="success"
                        type='submit'
                        onClick={handleClick}
                        style={{
                            width: '100%',
                            padding: '10px',
                            fontSize: '24px',
                            marginTop:'20px',
                            fontWeight:'700',
                            backgroundColor: '#A700FA',
                            borderRadius:'15px',
                            letterSpacing: '2.08px'
                        }}

                    >
                        {submitButton}
                </Button>
            </div>
        </div>
    )
}
export default Support;