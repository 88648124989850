let user = JSON.parse(localStorage.getItem('data'));
if(!user) user={merchant:{status:''}, classic:{status:''}, prime:{status:''}, platinum:{status:''}, worldElite:{status:''}}

function isAnyCardActive(user) {
    const statuses = [
        user.merchant.status,
        user.classic.status,
        user.prime.status,
        user.platinum.status,
        user.worldElite.status
    ];
    return statuses.includes('Approved');
}

const isCardAnyCardActiveStatus = isAnyCardActive(user);
export default isCardAnyCardActiveStatus;