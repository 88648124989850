import './Footer.css'
import Logo from '../../Assets/HomeAssets/Logo.png'
import ButtonWhite from '../ButtonWhite/ButtonWhite'
import GooglePlay from '../../Assets/HomeAssets/GooglePlay.png'
import AppStore from '../../Assets/HomeAssets/AppStore.png'
import CountUp from 'react-countup';

import VisibilitySensor from 'react-visibility-sensor';


const Footer=()=>{
    return(
        <div className='footer-home-main' >

           

            {/* Logo */}
            <div className='footer-home-main-logo' >
                <img src={Logo} alt='Logo' />
                <p>Swap your GeoDB coins (BGEO) into BTC, ETH and BNB</p>
            </div>
            <hr/>
            <br/>
            <br/>
            {/* Main Footer */}
            <div className='footer-home-main-mainFooter' >

                {/* Row1 */}
                <div className='footer-home-main-mainFooter-row1' >

                    {/* Row1 Col1  */}
                    <div className='footer-home-main-mainFooter-row1-col1' >
                        <p className='footer-head1'>Contact</p>
                        <p className='footer-head1' >
                            info@geodbcoin.com <br/>
                            Tel: +1 (872) 282-4046 <br/>
                            124 Finchley Road, London, <br/>
                            United Kingdom, NW3 5JS <br/>
                        </p>
                    </div>

                    {/* Row1 Col2  */}
                    <div className='footer-home-main-mainFooter-row1-col2' >
                        <p className='footer-head1' >Join Our Telegram Channel</p>
                        <a href='https://t.me/geodbwallet' target='_blank' rel='noreferrer'>
                            <ButtonWhite buttonName='Join'/>
                        </a>

                    </div>

                    {/* Row1 Col3  */}
                    <div className='footer-home-main-mainFooter-row1-col3' >
                        <p className='footer-head1' >Withdraw Anywhere Anytime</p>
                        <br/>
                        <span className='footer-col3-style1' >
                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                {({ isVisible }) => (
                                <p className='footer-head2' style={{ height: 10 }}>
                                    {isVisible ? <CountUp end={141000} duration={20} /> : '1,41,000'}+
                                </p>
                                )}
                            </VisibilitySensor>
                            {/* <p className='footer-head2' >1,41,000+</p> */}
                            <p className='footer-head1' >Registered Users</p>
                        </span>
                        <span className='footer-col3-style1' >
                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                {({ isVisible }) => (
                                <p className='footer-head2' style={{ height: 10 }}>
                                    ${isVisible ? <CountUp end={280} duration={20} /> : 280}M+
                                </p>
                                )}
                            </VisibilitySensor>
                            {/* <p className='footer-head2' >$280M+</p> */}
                            <p className='footer-head1' >Airdrops Given</p>
                        </span>
                        <span className='footer-col3-style1' >
                            <VisibilitySensor partialVisibility offset={{ bottom: 200 }}>
                                {({ isVisible }) => (
                                <p className='footer-head2' style={{ height: 10 }}>
                                    ${isVisible ? <CountUp end={38} duration={20} /> : 38}M+
                                </p>
                                )}
                            </VisibilitySensor>
                            {/* <p className='footer-head2' >$37M+</p> */}
                            <p className='footer-head1' >Monthly Withdrawals</p>
                        </span>
                    </div>
                </div>

                {/* Row2 */}
                <div className='footer-home-main-mainFooter-row2' >

                    <div className='footer-appStore' >
                        <a href='https://geodbcoin-main.s3.eu-north-1.amazonaws.com/mobile_application/GeoDB+Coin_signed.apk' target='_blank' rel='noreferrer'>
                            <img src={AppStore} alt='App Store' className='footer-appStore-image1' />
                            <img src={GooglePlay} alt='Google Play' className='footer-appStore-image2' />
                        </a>
                    </div>
                    <p className='footer-copyright' >
                        © 2019 by GeoDBcoin . Powered and secured by Trust Wallet
                    </p>
                </div>
            </div>
        </div>
    )
}
export default Footer;