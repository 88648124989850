import './Signup.css';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import Button from '@mui/material/Button';
import countries from '../../Utils/AllCountries';
import { MenuItem } from '@mui/material';

import axios from 'axios'
import swal from 'sweetalert'
import { CircularProgress } from "@mui/material";
import {useNavigate, useSearchParams} from 'react-router-dom'

const Signup=()=>{
    const navigate =useNavigate();
    const [queryParameters]=useSearchParams();

    const [fName, setFName]=useState('');
    const [lName, setLName]=useState('');
    const [email, setEmail]=useState('');
    const [password, setPassword]=useState('');
    const [confirmPassword, setConfirmPassword]=useState('');
    const [phone, setPhone]=useState('');
    const [address1, setAddress1]=useState('');
    const [address2, setAddress2]=useState('');
    const [city, setCity]=useState('');
    const [state, setState]=useState('');
    const [postalCode, setPostalCode]=useState('');
    const [country, setCountry]=useState('');
    const [referralCode, setReferralCode]=useState(queryParameters.get('referralCode'));

    const [submitButton, setSubmitButton]=useState('SIGNUP');

    //Createing new form data
    const data = {
        "fName": fName,
        "lName": lName,
        "email": email,
        "password": password,
        "mobile": phone,
        "addressLine1": address1,
        "addressLine2": address2,
        "city": city,
        "state": state,
        "postalCode": postalCode,
        "country": country,
        "referralCode": referralCode
    }

    const handleSubmit= async (e)=> {
        e.preventDefault();
        setSubmitButton(<CircularProgress color="inherit" />)
        if(password!==confirmPassword){
          swal('Passwords do not match!', '', 'error');
          setSubmitButton('SIGNUP')
        }else{
    
          localStorage.setItem("tempData", JSON.stringify({fName: fName, lName: lName}))
          await axios
          .post(`${process.env.REACT_APP_SERVER}/user/signup`, data)
          .then(async (response) => {
            if (response.status === 200) {
              sessionStorage.setItem("tempUserId", response.data.userId);
              await axios.post(`${process.env.REACT_APP_SERVER}/user/sendSignupVerificationOtp`, {'userId': sessionStorage.getItem('tempUserId')})
                        .then(()=>{
                            navigate('/signup/verify')
                        })
              
            //   swal('', 'Account Created!', 'success').then(()=>navigate('/login'))
            } else if (response.status === 202) {
              swal(`${response.data.message}`,'', "error");
              setSubmitButton('SIGNUP')
            }
          });
        }
        
          
    
        
      }



    return(
        <div className='signup-main' >

            <h1>Signup</h1>
            <form className='signup-main-p1'>
                <TextField 
                    id="outlined-basic" 
                    label="First Name" 
                    variant="outlined" 
                    value={fName}
                    onChange={(e)=>setFName(e.target.value)}
                    autoComplete='given-name'
                    required
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Last Name" 
                    variant="outlined" 
                    value={lName}
                    onChange={(e)=>setLName(e.target.value)}
                    autoComplete='family-name'
                    required
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Email Address" 
                    variant="outlined" 
                    type='email'
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}
                    autoComplete='email'
                    required
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Password" 
                    variant="outlined" 
                    type='password'
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    required
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Confirm Password" 
                    variant="outlined" 
                    type='password'
                    value={confirmPassword}
                    onChange={(e)=>setConfirmPassword(e.target.value)}
                    required
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Phone" 
                    variant="outlined" 
                    type='tel'
                    value={phone}
                    onChange={(e)=>setPhone(e.target.value)}
                    autoComplete='tel'
                    required
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Street Address" 
                    variant="outlined" 
                    type='text'
                    value={address1}
                    onChange={(e)=>setAddress1(e.target.value)}
                    required
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Street Address Line 2" 
                    variant="outlined" 
                    type='text'
                    value={address2}
                    onChange={(e)=>setAddress2(e.target.value)}
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="City" 
                    variant="outlined" 
                    type='text'
                    value={city}
                    onChange={(e)=>setCity(e.target.value)}
                    required
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Region/State/Province" 
                    variant="outlined" 
                    type='text'
                    value={state}
                    onChange={(e)=>setState(e.target.value)}
                    required
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Postal / Zip Code" 
                    variant="outlined" 
                    type='number'
                    value={postalCode}
                    onChange={(e)=>setPostalCode(e.target.value)}
                    required
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Country"
                    defaultValue=""
                    value={country}
                    autoComplete='country'
                    required
                    // helperText="Please select your currency"
                    onChange={(e)=>setCountry(e.target.value)}
                    style={{
                        backgroundColor:'#E3E3F6',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                >
                    {countries.map((option) => (
                        <MenuItem key={option.code} value={option.name}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField 
                    id="outlined-basic" 
                    label="Referral Code" 
                    variant="outlined" 
                    type='text'
                    value={referralCode}
                    onChange={(e)=>setReferralCode(e.target.value)}
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                />
                
                
            </form>
            <div className='signup-main-p2' >
                <Button 
                        variant="contained" 
                        color="success"
                        onClick={handleSubmit}
                        style={{
                            width: '100%',
                            padding: '10px',
                            fontSize: '24px',
                            marginTop:'20px',
                            fontWeight:'700',
                            backgroundColor: '#A700FA',
                            borderRadius:'15px',
                            letterSpacing: '2.08px'
                        }}

                    >
                        {submitButton}
                </Button>
            </div>
            <p className='signup-main-p3'>Already signed up? <span onClick={()=>navigate('/login')} > Login</span></p>

        </div>
    )
}
export default Signup;