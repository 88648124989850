import { useEffect } from "react";

const WhiteBackground=()=>{
    useEffect(()=>{
        document.getElementById('app').style.backgroundColor='white'
    }, [])
    return(
        <></>
    )
}


const BlueBackground=()=>{
    useEffect(()=>{
        document.getElementById('app').style.backgroundColor='#EAF2FF'
    }, [])
    return(
        <></>
    )
}

export {WhiteBackground, BlueBackground};