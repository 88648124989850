import {useEffect} from 'react'
import './AIMiningPlan.css'

import AOS from 'aos'
import Card1 from '../../Components/Card1/Card1'
import ConnectWallet from '../../Components/ConnectWallet/ConnectWallet'

import Avalon from '../../Assets/AIMiningPlan/AIMiningPlan_Avalon.png'
import Whatsmine from '../../Assets/AIMiningPlan/AIMiningPlan_Whatsmine.png'
import Antminer from '../../Assets/AIMiningPlan/AIMiningPlan_Antminer.png'
import AntminerMiner from '../../Assets/AIMiningPlan/AIMiningPlan_AntminerMiner.png'

const AIMiningPlan = () =>{
    useEffect(()=>{
        AOS.init({
            once: true,
            // disable: "phone",
            duration: 1000,
            easing: "ease-out-cubic",
        });
    },[])
  return (
    <div className="membership-main">

      <div className="membership-main-bg1 aiMining-main-bg1" >
        <p className="membership-main-bg1-p1" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500"></p>
        <p className="membership-main-bg1-p2" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">Become a Crypto Miner with us!</p>
        <p className="membership-main-bg1-p3" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">
            Boost your mining profitability with our AI-powered mining solutions.
            Harnessing advanced algorithms, our technology ensures efficient
            resource extraction, maximizing returns sustainably
        </p>
      </div>

      <div className="membership-main-bg2 aiMining-main-bg2">
        <h2 className="membership-main-bg2-p1 aiMining-main-bg2-p1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="100" data-aos-offset="0" data-aos-duration="1500">Crypto Mining Plans</h2>
        <div className="membership-main-bg2-p2" >
          <Card1
            image={Avalon}
            head1="BITCOIN AVALON-1366"
            head2="Mining Fees : $350"
            bodyL1='> Daily earnings : $53.50'
            bodyL2='> Total earnings : $1,605.00'
            bodyL3='> Plan duration : 30 days'
            bodyL4='> Withdrawal Time : After Plan Ends'
            bodyL5='> Free Maintenance and Chat Support'
            bodyL6='> Withdrawal Duration : 60 Minutes'
            buttonLink="/user/plan/BITCOIN AVALON-1366"
          />
          <Card1
            image={Whatsmine}
            head1="BITCOIN WHATSMINE RM50S130T"
            head2="Mining Fees : $1050"
            bodyL1='> Daily earnings : $83.02'
            bodyL2='> Total earnings : $2490.60'
            bodyL3='> Plan duration : 30 days'
            bodyL4='> Withdrawal Time : After Plan Ends'
            bodyL5='> Free Maintenance and Chat Support'
            bodyL6='> Withdrawal Duration : 60 Minutes'
            buttonLink="/user/plan/BITCOIN WHATSMINE RM50S130T"
          />
          <Card1
            image={Antminer}
            head1="BITCOIN ANTMINER S19 XP HYD"
            head2="Mining Fees : $4500"
            bodyL1='> Daily earnings : $201.00'
            bodyL2='> Total earnings : $6030.00'
            bodyL3='> Plan duration : 30 days'
            bodyL4='> Withdrawal Time : After Plan Ends'
            bodyL5='> Free Maintenance and Chat Support'
            bodyL6='> Withdrawal Duration : 60 Minutes'
            buttonLink="/user/plan/BITCOIN ANTMINER S19 XP HYD"
          />
          <Card1
            image={AntminerMiner}
            head1="BITCOIN ANTMINER MINER S19 PRO HYD"
            head2="Mining Fees : $10,800"
            bodyL1='> Daily earnings : $684.00'
            bodyL2='> Total earnings : $20,520.00'
            bodyL3='> Plan duration : 30 days'
            bodyL4='> Withdrawal Time : After Plan Ends'
            bodyL5='> Free Maintenance and Chat Support'
            bodyL6='> Withdrawal Duration : 60 Minutes'
            buttonLink="/user/plan/BITCOIN ANTMINER MINER S19 PRO HYD"
          />
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <ConnectWallet/>
    </div>
  );

}
export default AIMiningPlan;