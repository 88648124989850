import axios from "axios";
import config from "../config/default";

const updateLocaldata = async () =>{
    const response = await axios.post(
        `${config.server.link}/user/getUser`,
        
        {userId: JSON.parse(localStorage.getItem('data')).userId},
        {
            headers:{
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        }
    )
    // console.log(response.data)
    await localStorage.setItem('data', JSON.stringify(response.data))
}
export default updateLocaldata;