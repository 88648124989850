import './ActivateDebitCard.css'
import Card2 from '../../Components/Card2/Card2';

import Merchant from '../../Assets/Cards/SampleCards/MerchantVisaCard_Sample.png'
import Classic from '../../Assets/Cards/SampleCards/ClassicVisaCard_Sample.png'
import Prime from '../../Assets/Cards/SampleCards/PrimeVisaCard_Sample.png'
import Platinum from '../../Assets/Cards/SampleCards/PlatinumVisaCard_Sample.png'
import WorldElite from '../../Assets/Cards/SampleCards/WorldEliteVisaCard_Sample.png'

const ActivateDebitCard =() =>{
    return(
        <div className='activateDebitCard-main' >
            
            <h2>Activate Debit Card</h2>
            <div className='activateDebitCard-main-cards' >
                <hr/>
                <Card2
                    head1='Merchant Visa Card'
                    head2='$ 100'
                    head3='Withdrawal Limit $5000 / Day'
                    image={Merchant}
                    bodyL1='Worldwide Accepted'
                    bodyL2='Get Rewards on Purchase'
                    bodyL3='Instant Approval'
                    buttonLink='/user/cardDetails/Merchant'
                />

                <hr/>
                <Card2
                    head1='Classic Visa Card'
                    head2='$ 200'
                    head3='Withdrawal Limit $20,000 / Day'
                    image={Classic}
                    bodyL1='Worldwide Accepted'
                    bodyL2='Get Rewards on Purchase'
                    bodyL3='Instant Approval'
                    buttonLink='/user/cardDetails/Classic'
                />

                <hr/>
                <Card2
                    head1='Prime Visa Card'
                    head2='$ 500'
                    head3='Withdrawal Limit $50,000 / Day'
                    image={Prime}
                    bodyL1='Worldwide Accepted'
                    bodyL2='Get Rewards on Purchase'
                    bodyL3='Instant Approval'
                    buttonLink='/user/cardDetails/Prime'
                />

                <hr/>
                <Card2
                    head1='Platinum Visa Card'
                    head2='$ 1000'
                    head3='Withdrawal Limit $100,000 / Day'
                    image={Platinum}
                    bodyL1='Worldwide Accepted'
                    bodyL2='Get Rewards on Purchase'
                    bodyL3='Instant Approval'
                    buttonLink='/user/cardDetails/Platinum'
                />

                <hr/>
                <Card2
                    head1='World Elite Visa Card'
                    head2='$ 2000'
                    head3='Withdrawal Limit Unlimited'
                    image={WorldElite}
                    bodyL1='Worldwide Accepted'
                    bodyL2='Get Rewards on Purchase'
                    bodyL3='Instant Approval'
                    buttonLink='/user/cardDetails/worldElite'
                />
            </div>
            <br/>
            <br/>
        </div>
    )
}
export default ActivateDebitCard;