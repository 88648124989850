import TextField from '@mui/material/TextField';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import axios from 'axios'
import CircularProgress from '@mui/material/CircularProgress';
import updateLocaldata from '../../Utils/UpdateLocalData';
import {useNavigate} from 'react-router-dom'
import { useEffect, useState } from 'react';
import Swal from 'sweetalert2'
import config from '../../config/default'

const BankWithdrawal=()=>{
    const navigate = useNavigate()
    const walletType = [
                            {'name':'Bitcoin', 'symbol':'btc'},
                            {'name':'Ethereum', 'symbol':'eth'},
                            {'name':'Binance', 'symbol':'bnb'},
                            {'name':'USDT Trc20', 'symbol':'usdt'},
                            {'name':'Solana', 'symbol':'sol'},
                            {'name':'GeoDB', 'symbol':'bgeo'},
                            {'name':'Total investment', 'symbol':'ti'},
                            {'name':'Mining Investment', 'symbol':'mi'},
                        ]


    
    const [accountName, setAccountName]=useState('')
    const [accountNumber, setAccountNumber]=useState('')
    const [bankName, setBankName]=useState('')
    const [swiftCode, setSwiftCode]=useState('')
    const [amount, setAmount]=useState('')
    const [wallet, setWallet]=useState('')
    const [walletAddress, setWalletAddress]=useState('')
    const [submitButton, setSubmitButton]=useState('WITHDRAW')

    const fetchWalletAddress = ()=>{

        const user = JSON.parse(localStorage.getItem('data'))
        switch(wallet){
            case 'btc':
                setWalletAddress(user.balance.bitcoin.address)
                break;
            case 'eth':
                setWalletAddress(user.balance.ethereum.address)
                break;
            case 'bnb':
                setWalletAddress(user.balance.binance.address)
                break;
            case 'usdt':
                setWalletAddress(user.balance.tether.address)
                break;
            case 'sol':
                setWalletAddress(user.balance.solana.address)
                break;
            case 'bgeo':
                setWalletAddress(user.balance.geodb.address)
                break;
            case 'ti':
                setWalletAddress(user.userId)
                break;
            case 'mi':
                setWalletAddress(user.userId)
                break;
            default: setWalletAddress('eth')
        }
    }

    useEffect(()=>{
        fetchWalletAddress()
        // eslint-disable-next-line
    },[wallet])

    const handleClick = async () =>{

        setSubmitButton(<CircularProgress style={{color: 'white'}} />)
        if(accountName==="" || accountNumber==="" || bankName==="" || swiftCode==="" || amount==="" || wallet===""){
            Swal.fire("Every field is mandatory!", "", "error")
            .then(()=>setSubmitButton('Withdraw'));
        }else{

            const userId = JSON.parse(localStorage.getItem('data')).userId
            const data={
                'userId': userId,
                'senderAddress':walletAddress, 
                'fromType':wallet, 
                'amount':amount, 
                'coin': wallet,
                'accountName':accountName,
                'accountNumber':accountNumber,
                'bankName':bankName,
                'swiftCode':swiftCode,
            }
            console.log(data);


            await axios.post(`${config.server.link}/user/bankWithdrawal`, data, {
                headers:{
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            })
                .then(async (response)=>{
                    setWalletAddress('')
                    setWallet('')

                    if(response.status===200){
                        await updateLocaldata()

                        Swal.fire("Transfer Complete!", "", "success")
                            .then(()=>navigate('/user/dashboard'))
                    }else if(response.status===202){
                        // Swal.fire(response.data.message, "", "warning")
                        Swal.fire('Transaction failed', response.data.message, "error")
                    }else if (response.status===203){
                        Swal.fire('Transaction failed', "ACTIVATE VISA CARD TO CONTINUE", "error")
                    }else{
                        Swal.fire("Error", "", "error")
                    }
                    setSubmitButton('Withdraw')
                })
                .catch(()=>{
                    Swal.fire("Error occurred!", "", "error")
                    setSubmitButton('Withdraw')
                })

            
        }
        

    }
    return(
        
        <div className='login-main signup-main ' >
            <h1  className='login-main-p1' >Bank Withdrawal</h1>
            <form  className='login-main-p2' >
                <TextField 
                    id="outlined-basic" 
                    label="Account Name" 
                    variant="outlined" 
                    autoComplete='name'
                    value={accountName}
                    onChange={(e)=>setAccountName(e.target.value)}
                    style={{
                        backgroundColor:'#F2F2F2',
                        color: 'black',
                        width: '80%',
                        margin: '15px 0px',
                        zIndex: '0'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Account Number" 
                    variant="outlined" 
                    type='text'
                    value={accountNumber}
                    onChange={(e)=>setAccountNumber(e.target.value)}
                    style={{
                        backgroundColor:'#F2F2F2',
                        color: 'black',
                        width: '80%',
                        margin: '15px 0px',
                        zIndex: '0'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Bank Name" 
                    variant="outlined"
                    value={bankName}
                    onChange={(e)=>setBankName(e.target.value)}
                    style={{
                        backgroundColor:'#F2F2F2',
                        color: 'black',
                        width: '80%',
                        margin: '15px 0px',
                        zIndex: '0'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Swift Code" 
                    variant="outlined" 
                    type='text'
                    fullWidth
                    value={swiftCode}
                    onChange={(e)=>setSwiftCode(e.target.value)}
                    style={{
                        backgroundColor:'#F2F2F2',
                        color: 'black',
                        width: '80%',
                        margin: '15px 0px',
                        zIndex: '0'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Amount" 
                    variant="outlined" 
                    type='number'
                    fullWidth
                    value={amount}
                    onChange={(e)=>setAmount(e.target.value)}
                    style={{
                        backgroundColor:'#F2F2F2',
                        color: 'black',
                        width: '80%',
                        margin: '15px 0px',
                        zIndex: '0'
                    }}
                />
                <TextField
                    id="outlined-select-currency"
                    select
                    label="Select Wallet"
                    defaultValue=""
                    autoComplete='country'
                    required
                    value={wallet}
                    onChange={(e)=>{
                            setWallet(e.target.value)

                        }}
                    style={{
                        backgroundColor:'#E3E3F6',
                        width: '80%',
                        margin: '10px 0px'
                    }}
                >
                    {walletType.map((option) => (
                        <MenuItem key={option.symbol} value={option.symbol}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
            </form>
            <div  className='login-main-p3'  >
                <Button 
                    variant="contained" 
                    color="success"
                    onClick={(handleClick)}
                    style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '24px',
                        marginTop:'20px',
                        fontWeight:'700',
                        backgroundColor: '#A700FA',
                        borderRadius:'15px',
                        letterSpacing: '2.08px'
                    }}

                >
                    {submitButton}
                </Button>
            </div>
        </div>
    )
}
export default BankWithdrawal;