import './Home.css'


import HomeMain from '../../Components/HomeComponents/HomeMain/HomeMain';
import OurServices from '../../Components/HomeComponents/OurServices/OurServices';
import JoinUs from '../../Components/HomeComponents/JoinUs/JoinUs';

import AboutGeoDBcoin from '../../Components/HomeComponents/AboutGeoDBcoin/AboutGeoDBcoin';
// import FooterHome from '../../Components/HomeComponents/FooterHome/FooterHome';

import WhatsappImage from '../../Assets/HomeAssets/WhatsAppLogo.png'

const Home=()=>{
    return(
        <div className="home-main">
            <HomeMain/>
            <OurServices/>
            <JoinUs/>
            <AboutGeoDBcoin/>
            {/* <FooterHome/> */}
            <a href="https://wa.me/+15513440372" target="_blank" rel="noreferrer" >

                <img src={WhatsappImage} alt="TelegramImage" style={{ "position": "fixed", "bottom": "0", "right": "0", "width": "80px", "margin": "0 15px 15px 0", }} />
            </a>
        </div>
    )
}
export default Home;