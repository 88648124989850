import './FundTransfer.css'
import ChartWidget from '../../Components/ChartWidget/ChartWidget';
import Swal from 'sweetalert2'
import axios from 'axios'
import config from '../../config/default'
import {useState, useEffect} from 'react'

import updateLocaldata from '../../Utils/UpdateLocalData';
import {useNavigate} from 'react-router-dom'

import Transaction from '../../Components/Transaction/Transaction';
import TransactionTable from '../../Components/TransactionTable/TransactionTable';
import { CircularProgress } from '@mui/material';
import toast from 'react-hot-toast';



const UsdtFundTransfer = () => {

    const navigate=useNavigate()

    const [availableUsdtBalance, setAvailableUsdtBalance]=useState()

    const [qrValue, setQrValue]=useState(JSON.parse(localStorage.getItem('data')).balance.tether.address)
    const [qrImage, setQrImage]=useState(`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrValue}`)
    useEffect(()=>{
        updateLocaldata()
        setAvailableUsdtBalance(JSON.parse(localStorage.getItem('data')).balance.tether.amount)
        
        setQrValue(JSON.parse(localStorage.getItem('data')).balance.tether.address)
        setQrImage(`https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=${qrValue}`)
        return ()=>{};
    }, [qrValue])


    const [transaction, setTransaction]=useState(null)
    const [showTable, setShowTable]=useState(false)
    const [showTableButton, setShowTableButton]=useState('Show more')
    const toggleShowTable=()=>{
        showTable === true ? setShowTable(false) : setShowTable(true)
        showTable === true ? setShowTableButton('Show more') : setShowTableButton('Show less')
    }
    const fetchTransaction=async ()=>{
        const {data}=await axios.post(`${config.server.link}/user/getUserTransaction`, {userId: JSON.parse(localStorage.getItem('data')).userId}, {
            headers:{
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        })
        await setTransaction(data.transaction)
    }

    useEffect(()=>{
        fetchTransaction()
    },[])
    const handleSend = () =>{
        navigate('/user/fundTransfer/usdt/main')
    }
    const notify = () => toast.success('Wallet address copied to clipboard!');
    

    const handleReceive = () => {
        console.log(qrImage);
        Swal.fire({
            title: `Receiving USDT Trc20 Addres`,
            icon: 'info',
            showDenyButton: false,
            showCancelButton: false,
            confirmButtonText: "DONE",
            confirmButtonColor: '',
            showLoaderOnConfirm: true,
            denyButtonText: `Don't save`,
            html: `
                        <div class="container" style="position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%);">
                            <div class="copy-text" style="position: relative; padding: 10px; background: #fff; border: 1px solid #ddd; border-radius: 10px; display: flex;">
                                <input readonly id="textToCopy" type="text" class="text" value="${qrValue}" style="padding: 10px; font-size: 18px; color: #555; border: none; outline: none;" />
                                <button id="copyButton" style="padding: 10px; background: #5784f5; color: #fff; font-size: 18px; border: none; outline: none; border-radius: 10px; cursor: pointer; position: relative;">
                                    <i class="fa fa-clone"></i>
                                    <span style="content: 'Copied'; position: absolute; top: -45px; right: 0px; background: #5c81dc; padding: 8px 10px; border-radius: 20px; font-size: 15px; display: none;"></span>
                                    <span style="content: ''; position: absolute; top: -20px; right: 25px; width: 10px; height: 10px; background: #5c81dc; transform: rotate(45deg); display: none;"></span>
                                </button>
                            </div>
                        </div>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <img src='${qrImage}' alt='QR'/>
                        
                `,
            didOpen: () => {
                const copyButton = document.getElementById('copyButton');
                copyButton.addEventListener('click', function() {
                    const textToCopy = document.getElementById('textToCopy');
                    textToCopy.select();
                    textToCopy.setSelectionRange(0, 99999); // For mobile devices
                    document.execCommand('copy');
                    notify();
                });
            }
            
        })
    }

    return(
        <div className='fundTransfer-main' >
            <div className='fundTransfer-main-p1' >
                <h2>USDT / USD</h2>
            </div>
            <div className='fundTransfer-main-p2' >
                <h2>Availbale USDT Balance</h2>
                <h4>${availableUsdtBalance}</h4>
                <br/>
                <br/>
            </div>
            <div className='fundTransfer-main-p3' >
                <ChartWidget chartSymbol='BITSTAMP:USDTUSD' />
            </div>
            <div className='fundTransfer-main-p4' >
                
                <button 
                    className='fundTransfer-main-p4-e1 fundTransfer-button' 
                    onClick={handleSend}
                >Send</button>
                
                <button 
                    className='fundTransfer-main-p4-e2 fundTransfer-button' 
                    onClick={handleReceive}
                >Receive</button>
            </div>
            <div className='fundTransfer-main-p5' >
                <h4>Transaction</h4>
                <hr/>
                <div>
                {transaction === null

                    ? <CircularProgress/>
                    : transaction.filter(element => element.toType === 'USDT').slice(-5).reverse().map((element, index)=>{
                            return <Transaction
                                isCredit={element.action==='credit' ? true : false}
                                isDebit={element.action==='debit' ? true : false}
                                address={element.sender}
                                amount={element.amount}
                                currency={element.toType}
                                key={index}
                            />
                        })
                    
                }
                <button onClick={toggleShowTable} >{showTableButton}</button>

                {showTable && <TransactionTable transactionArray={transaction.filter(element => element.toType === 'USDT').reverse()} />}
                
                </div>
            </div>
        </div>
    )
}

export default UsdtFundTransfer;