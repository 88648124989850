import {useEffect} from 'react'
import './InvestAndLoan.css'

import AOS from 'aos'
import Card1 from '../../Components/Card1/Card1'
import ConnectWallet from '../../Components/ConnectWallet/ConnectWallet'

import Starter from '../../Assets/InvestAndLoan/InvestAndLoan_Starter.png'
import Medium from '../../Assets/InvestAndLoan/InvestAndLoan_Medium.png'
import Advanced from '../../Assets/InvestAndLoan/InvestAndLoan_Advanced.png'
import Empire from '../../Assets/InvestAndLoan/InvestAndLoan_Empire.png'

const InvestAndLoan =()=>{
    useEffect(()=>{
        AOS.init({
            once: true,
            // disable: "phone",
            duration: 1000,
            easing: "ease-out-cubic",
        });
    },[])
  return (
    <div className="membership-main">

      <div className="membership-main-bg1 investAndLoan-main-bg1" >
        <p className="membership-main-bg1-p1" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500"></p>
        <p className="membership-main-bg1-p2" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">Invest Wisely ForBrighter Tomorrow!</p>
        <p className="membership-main-bg1-p3" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">
            Explore unparalleled profit potential with our
            Crypto Investment platform. Leverage cutting-
            edge technology to navigate the dynamic
            cryptocurrency market and maximize returns
        </p>
      </div>

      <div className="membership-main-bg2 investAndLoan-main-bg2">
        <h2 className="membership-main-bg2-p1 investAndLoan-main-bg2-p1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="100" data-aos-offset="0" data-aos-duration="1500">Investment Plans</h2>
        <div className="membership-main-bg2-p2" >
          <Card1
            image={Starter}
            head1="Starter Investment Plan"
            head2="For 365 Days"
            bodyL1='> Investment: $100 - $999 (Each Month)'
            bodyL2='> Returns : 25% Monthly '
            bodyL3='> Withdrawal Time : After Mature'
            bodyL4='> Free Maintenance and Chat Support'
            bodyL5='> Returns after Maturity : 300% + 10% Loyalty Bonus'
            buttonLink="/user/plan/Starter Investment Plan"
          />
          <Card1
            image={Medium}
            head1="Medium Investment Plan"
            head2="For 365 Days"
            bodyL1='> Investment: $1000 - $9,999 (Each Month)'
            bodyL2='> Returns : 37% Monthly '
            bodyL3='> Withdrawal Time : After Mature'
            bodyL4='> Free Maintenance and Chat Support'
            bodyL5='> Returns after Maturity : 444% + 15% Loyalty Bonus'
            buttonLink="/user/plan/Medium Investment Plan"
          />
          <Card1
            image={Advanced}
            head1="Advanced Investment Plan"
            head2="For 365 Days"
            bodyL1='> Investment: $10,000 - $99,999 (Each Month)'
            bodyL2='> Returns : 53% Monthly '
            bodyL3='> Withdrawal Time : After Mature'
            bodyL4='> Free Maintenance and Chat Support'
            bodyL5='> Returns after Maturity : 636% + 20% Loyalty Bonus'
            buttonLink="/user/plan/Advanced Investment Plan"
          />
          <Card1
            image={Empire}
            head1="Empire Investment Plan"
            head2="For 365 Days"
            bodyL1='> Investment: $100,000+ (Each Month)'
            bodyL2='> Returns : 83% Monthly '
            bodyL3='> Withdrawal Time : After Mature'
            bodyL4='> Free Maintenance and Chat Support'
            bodyL5='> Returns after Maturity : 996% + 25% Loyalty Bonus'
            buttonLink="/user/plan/Empire Investment Plan"
          />
        </div>
      </div>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <ConnectWallet/>
    </div>
  );

}
export default InvestAndLoan;