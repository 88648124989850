import './VerifySignupOtp.css'
import TextField from '@mui/material/TextField';

import {useState} from 'react'
import Button from '@mui/material/Button';
import axios from 'axios'
import Swal from 'sweetalert2'
import { CircularProgress } from "@mui/material";
import {useNavigate} from 'react-router-dom'
import config from '../../config/default'

const VerifySignupOtp =()=>{
    
    const navigate=useNavigate()

    const [otp, setOtp]=useState('');


    const [verifyOtpButton, setVerifyOtpButton]=useState('Verify OTP')    

    const handleVerifyOtp=async(e)=>{
        e.preventDefault();
        setVerifyOtpButton(<CircularProgress color='inherit' />)

        const data={
            
            userId:sessionStorage.getItem('tempUserId'),
            otp:otp
        }

        await axios.post(`${config.server.link}/user/signupAfterVerification`, data)
            .then((response) => {
                setVerifyOtpButton("Verify OTP")
                if (response.status === 200) {

                    Swal.fire({text:'OTP Verified!', icon: "success"})
                        .then(() => {
                            navigate("/login")
                        })
                } else if (response.status === 202) {
                    Swal.fire({text:`${response.data.message}`,icon: "error"})

                }
            })

    }



    
    return(
        <div className="login-main signup-main" >
            <h1 className='login-main-p1' >VERIFY OTP</h1>
            <form className='login-main-p2' >
                


            
                    <TextField 
                        id="outlined-basic" 
                        label="Enter OTP" 
                        variant="outlined" 
                        type='number' 
                        value={otp}
                        onChange={(e)=>setOtp(e.target.value)}
                        autoComplete='otp'
                        style={{
                            backgroundColor:'#F2F2F2',
                            color:'black',
                            width: '80%',
                            margin: '15px 0px 0px 0px'
                        }}
                    />
                    
                    
                    <Button 
                        variant="contained" 
                        color="success"
                        type='submit'
                        onClick={handleVerifyOtp}
                        
                        style={{
                            width: '80%',
                            padding: '5px',
                            fontSize: '18px',
                            marginTop:'5px',
                            backgroundColor: '#A700FA',
                            borderRadius:'5px',
                            letterSpacing: '2.08px'
                        }}

                    >
                            {verifyOtpButton}
                    </Button>
                    <p className='signup-main-note' >Please check your inbox and spam folder.</p>
            
            

            


            

            </form>
        </div>
    )
}
export default VerifySignupOtp;