import './AboutGeoDBcoin.css'
import ButtonWhite from '../../ButtonWhite/ButtonWhite'

import {useNavigate} from 'react-router-dom'

const AboutGeoDBcoin=()=>{

    const navigate=useNavigate()

    return(
        <div className='aboutGeoDBcoin-main' >
            <p className='aboutGeoDBcoin-main-subHead' >About GeoDBcoin</p>
            <p className='aboutGeoDBcoin-main-head' >Create Account to Avail your Exclusive Sign Up Airdrop NOW!</p>
            <div className='aboutGeoDBcoin-main-button ' onClick={()=>navigate('/login')}><ButtonWhite buttonName='Sign Up' /></div>
            <div className='aboutGeoDBcoin-main-image' ></div>
        </div> 
    )
}
export default AboutGeoDBcoin