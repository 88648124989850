import {useEffect} from 'react'
import "./MembershipPlan.css";
import Card1 from "../../Components/Card1/Card1";
import AOS from 'aos';
import ConnectWallet from "../../Components/ConnectWallet/ConnectWallet";


import Silver from "../../Assets/MembershipPlan/MembershipPlan_Silver.png";
import Gold from "../../Assets/MembershipPlan/MembershipPlan_Gold.png";
import Platinum from "../../Assets/MembershipPlan/MembershipPlan_Platinum.png";
import Diamond from "../../Assets/MembershipPlan/MembershipPlan_Diamond.png";

const MembershipPlan = () => {

  useEffect(()=>{
    AOS.init({
        once: true,
        // disable: "phone",
        duration: 1000,
        easing: "ease-out-cubic",
    });
},[])
  return (
    <div className="membership-main">

      <div className="membership-main-bg1">
        <p className="membership-main-bg1-p1" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500"> BGEO Airdrop</p>
        <p className="membership-main-bg1-p2" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">Become a member!</p>
        <p className="membership-main-bg1-p3" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">
          GeoDBcoin partnered with Binance, Trust wallet and Metamask brings the
          biggest Airdrop for its members. Become a member and claim yours NOW!
        </p>
      </div>

      <div className="membership-main-bg2">
        <h2 className="membership-main-bg2-p1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="100" data-aos-offset="0" data-aos-duration="1500">Membership Plans</h2>
        <div className="membership-main-bg2-p2" >
          <Card1
            image={Silver}
            head1="Silver Membeship Plan"
            head2="$100 / one time"
            bodyL1='> Get $300 Airdrop (can withdraw after 30 days)'
            bodyL2='> Daily Withdrawal limit : 1000$'
            bodyL3='> Daily Withdrawal limit : 1000$'
            bodyL4='> Withdrawal Duration : 12 hours'
            buttonLink="/user/plan/Silver Membeship Plan"
          />
          <Card1
            image={Gold}
            head1="Gold Membeship Plan"
            head2="$500 / one time"
            bodyL1="> Get $1500 Airdrop (can withdraw after 30 days)"
            bodyL2='> Daily Withdrawal limit : 7500$'
            bodyL3='> Free Maintenance and Chat Support'
            bodyL4='> Withdrawal Duration : 3 hours'
            buttonLink="/user/plan/Gold Membeship Plan"
          />
          <Card1
            image={Platinum}
            head1="Platinum Membeship Plan"
            head2="$1000 / one time"
            bodyL1="> Get $3500 Airdrop (can withdraw after 30 days) "
            bodyL2='> Daily Withdrawal limit : $50,000'
            bodyL3='> Free Maintenance and Chat Support'
            bodyL4='> Withdrawal Duration : 1 hour'

            buttonLink="/user/plan/Platinum Membeship Plan"
          />
          <Card1
            image={Diamond}
            head1="Diamond Membeship Plan"
            head2="$2000 / one time"
            bodyL1="> Get $7500 Airdrop (can withdraw after 30 days) "
            bodyL2='> Daily withdrawal limit : Unlimited'
            bodyL3='> Free Maintenance and Chat Support'
            bodyL4='> Withdrawal Duration : Instant'
            buttonLink="/user/plan/Diamond Membeship Plan"
          />
        </div>
      </div>
      
      <ConnectWallet/>
    </div>
  );
};
export default MembershipPlan;
