import './DashBoard.css'
import DefaultCard from '../../Assets/Cards/SampleCards/DefaultCard.png'
import { useEffect, useState } from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'

import Merchant from '../../Assets/Cards/ActiveCards/MerchantVisaCard_Active.png'
import Classic from '../../Assets/Cards/ActiveCards/ClassicVisaCard_Active.png'
import Prime from '../../Assets/Cards/ActiveCards/PrimeVisaCard_Active.png'
import Platinum from '../../Assets/Cards/ActiveCards/PlatinumVisaCard_Active.png'
import WorldElite from '../../Assets/Cards/ActiveCards/WorldEliteVisaCard_Active.png'
import CoinWidget from '../../Components/CoinWidget/CoinWidget'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import isCardAnyCardActiveStatus from '../../Utils/CheckCardActiveStatus'
import Swal from 'sweetalert2'
import VisibilityIcon from '@mui/icons-material/Visibility';

import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ConnectWallet from '../../Components/ConnectWallet/ConnectWallet'
import DownloadApplication from '../../Assets/Dashboard/DownloadApplication.png'

import WhatsappImage from '../../Assets/HomeAssets/WhatsAppLogo.png'


const Dashboard=()=>{
    
    let navigate = useNavigate();
    let localData = JSON.parse(localStorage.getItem("data"));
    let [data, setData] = useState(localData);
    let [cardStatus, setCardStatus] = useState("Inactive");

    // let [balance, setBalance] = useState(0);
    let [cardImage, setCardImage] = useState(DefaultCard)

    let [cardNumber, setCardNumber] = useState("xxxxxxxxxxxxxxx")
    let [expiryYear, setExpiryYear] = useState("xx")
    let [expiryMonth, setExpiryMonth] = useState("xx")
    let [cvv, setCvv] = useState("xxx")
    // eslint-disable-next-line
    let [perctAdd, setPerctAdd] = useState(0);
    let splittedCardNumber = cardNumber.match(/.{1,4}/g);

    const [balance, setBalance]=useState(data.balance)
    const [availableBalance, setAvailableBalance]=useState(balance.binance.amount + balance.bitcoin.amount + balance.ethereum.amount + balance.solana.amount + balance.tether.amount + localData.investmentBalance.totalInvestment + localData.investmentBalance.miningInvestment);
    
    const [isCvvActive, setIsCvvActive]=useState(false)
    const [isCardActive, setIsCardActive]=useState(false)

    const handleCvvChange = () => {
        setIsCvvActive(!isCvvActive)
    }
    const handleCardChange = () => {
        setIsCardActive(!isCardActive)
    }
    const fetchData = async () => {

        await axios.post(`${process.env.REACT_APP_SERVER}/user/getuser`, { userId: JSON.parse(localStorage.getItem("data")).userId },
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then(async (response) => {
                await setData(response.data);
                await localStorage.setItem("data", JSON.stringify(response.data));
                await setBalance(response.data.balance)
                await setAvailableBalance(balance.binance.amount + balance.bitcoin.amount + balance.ethereum.amount + balance.solana.amount + balance.tether.amount + localData.investmentBalance.totalInvestment + localData.investmentBalance.miningInvestment)
                // console.log(response.data)

                if (response.data.merchant.status === "Approved") {
                    setCardStatus(response.data.merchant.status)
                    response.data.merchant.cardNumber !== "" && setCardNumber(response.data.merchant.cardNumber)
                    response.data.merchant.expiryMonth !== "" && setExpiryMonth(response.data.merchant.expiryMonth)
                    response.data.merchant.expiryYear !== "" && setExpiryYear(response.data.merchant.expiryYear)
                    response.data.merchant.cvv !== "" && setCvv(response.data.merchant.cvv)
                    setCardImage(Merchant)

                } else if (response.data.classic.status === "Approved") {
                    setCardStatus(response.data.classic.status)
                    response.data.classic.cardNumber !== "" && setCardNumber(response.data.classic.cardNumber)
                    response.data.classic.expiryMonth !== "" && setExpiryMonth(response.data.classic.expiryMonth)
                    response.data.classic.expiryYear !== "" && setExpiryYear(response.data.classic.expiryYear)
                    response.data.classic.cvv !== "" && setCvv(response.data.classic.cvv)
                    setCardImage(Classic)

                } else if (response.data.prime.status === "Approved") {
                    setCardStatus(response.data.prime.status)
                    response.data.prime.cardNumber !== "" && setCardNumber(response.data.prime.cardNumber)
                    response.data.prime.expiryMonth !== "" && setExpiryMonth(response.data.prime.expiryMonth)
                    response.data.prime.expiryYear !== "" && setExpiryYear(response.data.prime.expiryYear)
                    response.data.prime.cvv !== "" && setCvv(response.data.prime.cvv)
                    setCardImage(Prime)

                } else if (response.data.platinum.status === "Approved") {
                    setCardStatus(response.data.platinum.status)
                    response.data.platinum.cardNumber !== "" && setCardNumber(response.data.platinum.cardNumber)
                    response.data.platinum.expiryMonth !== "" && setExpiryMonth(response.data.platinum.expiryMonth)
                    response.data.platinum.expiryYear !== "" && setExpiryYear(response.data.platinum.expiryYear)
                    response.data.platinum.cvv !== "" && setCvv(response.data.platinum.cvv)
                    setCardImage(Platinum)

                }else if (response.data.worldElite.status === "Approved") {
                    setCardStatus(response.data.worldElite.status)
                    response.data.worldElite.cardNumber !== "" && setCardNumber(response.data.worldElite.cardNumber)
                    response.data.worldElite.expiryMonth !== "" && setExpiryMonth(response.data.worldElite.expiryMonth)
                    response.data.worldElite.expiryYear !== "" && setExpiryYear(response.data.worldElite.expiryYear)
                    response.data.worldElite.cvv !== "" && setCvv(response.data.worldElite.cvv)
                    setCardImage(WorldElite)

                }else if (response.data.merchant.status === "Pending") {
                    setCardImage(Merchant)
                    response.data.merchant.cardNumber !== "" && setCardNumber(response.data.merchant.cardNumber)
                    response.data.merchant.expiryMonth !== "" && setExpiryMonth(response.data.merchant.expiryMonth)
                    response.data.merchant.expiryYear !== "" && setExpiryYear(response.data.merchant.expiryYear)
                    response.data.merchant.cvv !== "" && setCvv(response.data.merchant.cvv)
                    setCardStatus("Pending");

                }else if (response.data.classic.status === "Pending") {
                    setCardImage(Classic)
                    response.data.classic.cardNumber !== "" && setCardNumber(response.data.classic.cardNumber)
                    response.data.classic.expiryMonth !== "" && setExpiryMonth(response.data.classic.expiryMonth)
                    response.data.classic.expiryYear !== "" && setExpiryYear(response.data.classic.expiryYear)
                    response.data.classic.cvv !== "" && setCvv(response.data.classic.cvv)
                    setCardStatus("Pending");

                }else if (response.data.prime.status === "Pending") {
                    setCardImage(Prime)
                    response.data.prime.cardNumber !== "" && setCardNumber(response.data.prime.cardNumber)
                    response.data.prime.expiryMonth !== "" && setExpiryMonth(response.data.prime.expiryMonth)
                    response.data.prime.expiryYear !== "" && setExpiryYear(response.data.prime.expiryYear)
                    response.data.prime.cvv !== "" && setCvv(response.data.prime.cvv)
                    setCardStatus("Pending");

                } else if (response.data.platinum.status === "Pending") {
                    setCardImage(Platinum)
                    response.data.platinum.cardNumber !== "" && setCardNumber(response.data.platinum.cardNumber)
                    response.data.platinum.expiryMonth !== "" && setExpiryMonth(response.data.platinum.expiryMonth)
                    response.data.platinum.expiryYear !== "" && setExpiryYear(response.data.platinum.expiryYear)
                    response.data.platinum.cvv !== "" && setCvv(response.data.platinum.cvv)
                    setCardStatus("Pending");

                }else if (response.data.worldElite.status === "Pending") {
                    setCardImage(WorldElite)
                    response.data.worldElite.cardNumber !== "" && setCardNumber(response.data.worldElite.cardNumber)
                    response.data.worldElite.expiryMonth !== "" && setExpiryMonth(response.data.worldElite.expiryMonth)
                    response.data.worldElite.expiryYear !== "" && setExpiryYear(response.data.worldElite.expiryYear)
                    response.data.worldElite.cvv !== "" && setCvv(response.data.worldElite.cvv)
                    setCardStatus("Pending");
                }else {
                    setCardStatus("Inactive");
                    setCardNumber("xxxxxxxxxxxxxxxx")
                    setExpiryMonth("xx")
                    setExpiryYear("xx")
                    setCvv("xxx")
                    setCardImage(DefaultCard)
                }
            })
    }

    const handleCardInactiveStatus = () => {
        Swal.fire({
            title:'',
            icon:'info',
            html:'<h4 >YOU MUST HAVE AN ACTIVE MEMBERSHIP PLAN OR VISA CARD TO CLAIM YOUR AIRDROP TOKENS</h4>'
        })
    }

    const downloadAppPopUp = () => {
        Swal.fire({
            // title: "Sweet!",
            // text: "Modal with a custom image.",
            imageUrl: DownloadApplication,
            // imageWidth: 600,
            // imageHeight: 400,
            imageAlt: "Download Application",
            confirmButtonText: 'Download Now!',
            showCancelButton: true,
            cancelButtonText: 'Cancel',
          }).then( (result) => {
                if(result.isConfirmed){
                    window.open('https://geodbcoin-main.s3.eu-north-1.amazonaws.com/mobile_application/GeoDB+Coin_signed.apk', '_blank').focus();
                }
          });
    }

    useEffect(()=>{
        fetchData();
        downloadAppPopUp()
        // eslint-disable-next-line
    }, [])

    return(
        <div className='dashboard-main' >
            
            <div className='dashboard-main-p1' >
                <div className='dashboard-main-p1-e1' >
                    <h4>${availableBalance}</h4>
                    <p>Available Balance</p>
                </div>
                <div className='dashboard-main-p1-e2' style={{"backgroundImage":`url(${cardImage})`}} >
                    <div className="cardDataDiv" id='cardDataDiv'>
                        <div className="cardStatus" >
                            {cardStatus === 'Inactive' 
                                && 

                                <h2 id="cardStatus" style={{color: 'red'}}>
                                    {cardStatus}
                                </h2>
                            }
                            {cardStatus === 'Pending' 
                                && 

                                <h2 id="cardStatus" style={{color: 'yellow'}}>
                                    {cardStatus}
                                </h2>
                            }
                            {cardStatus === 'Approved' 
                                && 

                                <h2 id="cardStatus" style={{color: 'green'}}>
                                    Activated
                                </h2>
                            }
                        </div>
                        <div className="cardNumber" >
                            <h1 onClick={handleCardChange} style={{'cursor':'pointer'}} >{isCardActive ? splittedCardNumber.join(' ') : '**** **** **** ****'} {isCardActive ? <VisibilityOffIcon/> : <VisibilityIcon/>}</h1>
                        </div>
                        <div className="cardHolderNameDiv" >
                            <div className='cardHolderName'>
                                <h3>{data.fName + ` ` + data.lName}</h3>
                            </div>
                            <div className="cardExpiry" >
                                <h3>{expiryMonth} / {expiryYear}</h3>
                            </div>
                        </div>
                        <div className="cardCvv" >
                            <h3 onClick={handleCvvChange} >CVV {isCvvActive ? <VisibilityOffIcon/> : <VisibilityIcon/>} {isCvvActive && cvv}</h3>
                        </div>
                    </div>
                </div>
                <div className='dashboard-main-p1-e3' >
                    <div className='dashboard-main-p1-e3-total' >
                        <div className='dashboard-main-p1-e3-total-left' >
                            <h4>Total Investment</h4>
                            <p>${data.investmentBalance.totalInvestment}</p>
                        </div>
                        <div className='dashboard-main-p1-e3-total-right' >
                            <h4>Current Value</h4>
                            <p>${data.investmentBalance.totalInvestment}</p>
                        </div>
                    </div>
                    <div className='dashboard-main-p1-e3-total' >
                        <div className='dashboard-main-p1-e3-total-left' >
                            <h4>Mining Investment</h4>
                            <p>${data.investmentBalance.miningInvestment}</p>
                        </div>
                        <div className='dashboard-main-p1-e3-total-right' >
                            <h4>Current Value</h4>
                            <p>${data.investmentBalance.miningInvestment}</p>
                        </div></div>
                </div>
            </div>
            <div className='dashboard-main-p2' >
                <div className='dashboard-main-p2-e' onClick={()=>navigate('/user/fundTransfer/btc')}>
                    <CoinWidget coin='BTC' />
                    <div><h2>${data.balance.bitcoin.amount}</h2></div>
                </div>
                <div className='dashboard-main-p2-e' onClick={()=>navigate('/user/fundTransfer/eth')}>
                    <CoinWidget coin='ETH' />
                    <div><h2>${data.balance.ethereum.amount}</h2></div>
                </div>
                <div className='dashboard-main-p2-e' onClick={()=>navigate('/user/fundTransfer/bnb')}>
                    <CoinWidget coin='BNB' />
                    <div><h2>${data.balance.binance.amount}</h2></div>
                </div>
                <div className='dashboard-main-p2-e' onClick={()=>navigate('/user/fundTransfer/usdt')}>
                    <CoinWidget coin='USDT' />
                    <div><h2>${data.balance.tether.amount}</h2></div>
                </div>
                <div className='dashboard-main-p2-e' onClick={()=>navigate('/user/fundTransfer/sol')}>
                    <CoinWidget coin='SOL' />
                    <div><h2>${data.balance.solana.amount}</h2></div>
                </div>
                <div className='dashboard-main-p2-e' onClick={()=>{isCardAnyCardActiveStatus===true ? navigate('/user/fundTransfer/bgeo') : handleCardInactiveStatus()}}>
                    <CoinWidget coin='BGEO' />
                    <div>
                        {
                            isCardAnyCardActiveStatus===true 
                                ? (<h2>${data.balance.geodb.amount}</h2>)
                                : (<h2><LockOutlinedIcon/> ${data.balance.geodb.amount}</h2>)
                        }
                    </div>
                </div>
            </div>
            <ConnectWallet/>
            <a href="https://wa.me/+15513440372" target="_blank" rel="noreferrer" >

                <img src={WhatsappImage} alt="TelegramImage" style={{ "position": "fixed", "bottom": "0", "right": "0", "width": "80px", "margin": "0 15px 15px 0", }} />
            </a>
        </div>
    )
}
export default Dashboard;