import './PaymentOption.css'
import {useNavigate} from 'react-router-dom'
import Bitcoin from '../../Assets/PaymentOption/Bitcoin_Image.png'
import Ethereum from '../../Assets/PaymentOption/Ethereum_Image.png'
import Binance from '../../Assets/PaymentOption/Binance_Image.png'
import Tether from '../../Assets/PaymentOption/Tether_Image.png'
import Solana from '../../Assets/PaymentOption/Solana_Image.png'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';


const PaymentOption=()=>{

    const navigate = useNavigate();
    return(
        <div className='paymentOption-main' >
            
            <div className='paymentOption-main-card' >
                <h2 className='paymentOption-main-p1' >Payment Option</h2>
                <div className='paymentOption-main-p2' >
                    <div className='paymentOption-main-p2-paymentButton' onClick={()=>navigate('/user/paymentOption/bitcoin')}>
                        <div className='paymentOption-main-p2-paymentButton-left'>
                            <img src={Bitcoin} alt='Bitcoin' />
                            <h4>Bitcoin (BTC)</h4>
                        </div>
                        <div className='paymentOption-main-p2-paymentButton-right' >
                            <ArrowForwardIosIcon/>
                        </div>
                    </div>

                    <div className='paymentOption-main-p2-paymentButton' onClick={()=>navigate('/user/paymentOption/ethereum')}>
                        <div className='paymentOption-main-p2-paymentButton-left'>
                            <img src={Ethereum} alt='Bitcoin' />
                            <h4>Ethereum (ETH)</h4>
                        </div>
                        <div className='paymentOption-main-p2-paymentButton-right' >
                            <ArrowForwardIosIcon/>
                        </div>
                    </div>

                    <div className='paymentOption-main-p2-paymentButton' onClick={()=>navigate('/user/paymentOption/binance')}>
                        <div className='paymentOption-main-p2-paymentButton-left'>
                            <img src={Binance} alt='Bitcoin' />
                            <h4>Binance Coin (BNB)</h4>
                        </div>
                        <div className='paymentOption-main-p2-paymentButton-right' >
                            <ArrowForwardIosIcon/>
                        </div>
                    </div>

                    <div className='paymentOption-main-p2-paymentButton' onClick={()=>navigate('/user/paymentOption/tether')}>
                        <div className='paymentOption-main-p2-paymentButton-left'>
                            <img src={Tether} alt='Bitcoin' />
                            <h4>Tether (USDT)</h4>
                        </div>
                        <div className='paymentOption-main-p2-paymentButton-right' >
                            <ArrowForwardIosIcon/>
                        </div>
                    </div>

                    <div className='paymentOption-main-p2-paymentButton' onClick={()=>navigate('/user/paymentOption/solana')}>
                        <div className='paymentOption-main-p2-paymentButton-left'>
                            <img src={Solana} alt='Bitcoin' />
                            <h4>Solana (SOL)</h4>
                        </div>
                        <div className='paymentOption-main-p2-paymentButton-right' >
                            <ArrowForwardIosIcon/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
export default PaymentOption;