import "./OurServices.css";
import {useEffect} from 'react'
import Image1 from "../../../Assets/HomeAssets/OurServices-Image1.png";

import MineForFuture from "../../../Assets/HomeAssets/MineForFuture-HeaderMain.png";
import EarnForFuture from "../../../Assets/HomeAssets/EarnForFuture-HeaderMain.png";
import SaveForFuture from "../../../Assets/HomeAssets/SaveForFuture-HeaderMain.png";

import AIMiningBotImage1 from "../../../Assets/HomeAssets/AI-MiningBot-Image1.png";
import AIMiningBotImage2 from "../../../Assets/HomeAssets/AI-MiningBot-Image2.png";
import AIMiningBotImage3 from "../../../Assets/HomeAssets/AI-MiningBot-Image3.png";


const OurServices = () => {
  useEffect(()=>{
    
  },[])
  return (
    <div className="our-services-main" id='our-services' >
      <div className="our-services-main-a1">
        <img src={Image1} alt="Image1" loading='lazy'/>
      </div>
      <div className="our-services-main-head home-main-c1-head">
        <span>Explore</span> Our Services
      </div>
      <div className="our-services-main-subHead home-main-c1-subHead">
        Our mission is to democratize the Big Data market and open the door to
        90% of uncharted Data Market while building positive Carbon Credits in
        the process
      </div>
      <br />
      <br />
      <br />
      <br />
      <div className="our-services-main-cards">
        {/* Card 1 */}
        <div className="our-services-main-card-left our-services-card1">
          {/* Card Inside */}
          <div className="our-services-main-card-main">
            {/* Left Top */}
            <div className="services-card-div1">
              <img src={MineForFuture} alt="MineForFuture" loading='lazy'/>
            </div>

            {/* Left Bottom */}
            <div className="services-card-div2">
              <p>
                Introducing our AI Mining Bot – a cutting-edge solution
                redefining resource extraction with unprecedented efficiency,
                safety, and sustainability through the power of artificial
                intelligence. Welcome to the future of mining.
              </p>
            </div>

            {/* Right */}
            <div className="services-card-div3">
              <img src={AIMiningBotImage1} alt="AIMiningBotImage1" loading='lazy'/>
            </div>
          </div>
        </div>

        {/* Card 2 */}
        <div className="our-services-main-card-right our-services-card2">
          {/* Card Inside */}
          <div className="our-services-main-card-main">
            {/* Left Top */}
            <div className="services-card-div1">
              <img src={EarnForFuture} alt="MineForFuture" loading='lazy'/>
            </div>

            {/* Left Bottom */}
            <div className="services-card-div2">
              <p>
                Explore financial innovation with our AI Trading Bot – a
                game-changer leveraging artificial intelligence for precision,
                profitability, and exceptional returns in your investment
                strategy.
              </p>
            </div>

            {/* Right */}
            <div className="services-card-div3">
              <img src={AIMiningBotImage2} alt="AIMiningBotImage1" loading='lazy'/>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="our-services-main-card-left our-services-card3">
          {/* Card Inside */}
          <div className="our-services-main-card-main">
            {/* Left Top */}
            <div className="services-card-div1">
              <img src={SaveForFuture} alt="MineForFuture" loading='lazy'/>
            </div>

            {/* Left Bottom */}
            <div className="services-card-div2">
              <p>
                Step into a realm of financial empowerment. Our Investment and
                Loan services are tailored to fuel your ambitions and elevate
                your financial journey.
              </p>
            </div>

            {/* Right */}
            <div className="services-card-div3">
              <img src={AIMiningBotImage3} alt="AIMiningBotImage1" loading='lazy'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurServices;
