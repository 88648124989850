import './QR.css'
import QRCode from 'react-qr-code'

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import {useNavigate} from 'react-router-dom'
import {useState, useEffect} from 'react'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import toast from 'react-hot-toast';

import axios from 'axios'
import config from '../../config/default'
const Tether_QR=()=>{
    const navigate=useNavigate()
    const [qrValue, setQrValue]=useState('')

    useEffect(()=>{
        axios.get(`${config.server.link}/user/getPaymentAddress`, {
            headers:{
                'Authorization': `Bearer ${localStorage.getItem('token')}` 
            }
        })
        .then(response=>{
            setQrValue(response.data)
        })
    },[])
    const notify = () => toast.success('Address copied to clipboard!');

    return(
        <div className='qrPage-main' >
            
            <div className='qrPage-main-card' >
                <div className='qrPage-main-card-qrCode' >
                    <QRCode
                        size={256}
                        style={{ 
                                height: "auto", 
                                maxWidth: "100%", 
                                width: "60%", 
                                padding: '40px', 
                                backgroundColor:'rgba(194, 192, 255, 0.12)',
                                borderRadius: '32px'
                            }}
                        value={qrValue!=='' ? qrValue.paymentAddress.USDT : ''}
                        viewBox={`0 0 256 256`}
                    />
                </div>
                <CopyToClipboard text={qrValue!=='' ? qrValue.paymentAddress.USDT : ''} >
                    <div className='qrPage-main-card-address' onClick={notify} >
                        <ContentCopyIcon/>{qrValue!=='' ? qrValue.paymentAddress.USDT : ''}
                    </div>
                </CopyToClipboard>
                <div className='qrPage-main-card-message' ><span><ErrorOutlineIcon/></span>Send only USDT to this address.</div>
                <div className='qrPage-main-card-message' ><span><ErrorOutlineIcon/></span>After payment send screenshot to our mail address : help.coingeodb@gmail.com</div>
                <div className='qrPage-main-card-message' ><span><ErrorOutlineIcon/></span>Refer atleast 2 people to get upto $10,000 worth of Free Airdrops</div>
                <button className='qrPage-main-card-button' onClick={()=>navigate('/user/dashboard')} >Dashboard</button>
            </div>
        </div>
    )
}
export default Tether_QR;