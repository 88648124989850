import './Header.css'
import { useState, useEffect } from 'react';
import {Link} from 'react-router-dom'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useNavigate} from 'react-router-dom'
import { Avatar} from '@mui/material';

import Logo from '../../Assets/HomeAssets/Logo.png'

const closeSlider=()=>{
    document.getElementById('menuToggleCheckbox').checked = false;
}

function getAvatarValue(inputString) {
    const words = inputString.split(' ');
    const firstLetters = words.map(word => word.charAt(0));
    const result = firstLetters.join('').toUpperCase();
    return result;
}

const Header = ()=>{

    const navigate=useNavigate()
    


    const [avatar, setAvatar]=useState(<><AccountCircleIcon/> Account</>)
    const isLoggedIn= localStorage.getItem('token') ? true : false

    useEffect(()=>{
        

        if(isLoggedIn){
            const fullName= JSON.parse(localStorage.getItem('data')).fName + ' ' + JSON.parse(localStorage.getItem('data')).lName
            setAvatar(<Avatar sx={{bgcolor: '#A700FA'}} >{getAvatarValue(fullName)}</Avatar>) 

        }

    },[isLoggedIn])

    return(
        <div className='header-main' >
            <div className="header-left" >
                <img src={Logo} alt='Logo' loading='lazy' onClick={()=>navigate('/')}/>
            </div>


            <div className="header-right navigation-large" >
                <Link to='/'>Home</Link>
                <Link to='/' onClick={()=>{ window.scrollTo({top: 2000,behavior: 'smooth'})}}>Services</Link>
                <Link to='/membershipPlan'>Membership Plan</Link>
                <Link to='/aiTradingPlan'>AI Trading Plan</Link>
                <Link to='/aiMiningPlan'>AI Mining Plan</Link>
                <Link to='/investAndLoan'>Invest and Loan</Link>
                <Link to='/'>GeoDB Coin</Link>
                <Link 
                    to={isLoggedIn ? '/user/dashboard' : '/login'}   
                    style={{color:'#A700FA'}}
                    >
                    {avatar}
                </Link>
            </div>


            <div className='navigation-small' >
                <nav class="menu--right" role="navigation">
                    <div class="menuToggle">
                        <input type="checkbox" id='menuToggleCheckbox'/>
                        <span></span>
                        <span></span>
                        <span></span>
                        <ul class="menuItem" onClick={closeSlider}>
                            <li><Link to='/'>Home</Link></li>
                            <li><Link to='/' onClick={()=>{ window.scrollTo({top: 2000,behavior: 'smooth'})}} >Services</Link></li>
                            <li><Link to='/membershipPlan'>Membership Plan</Link></li>
                            <li><Link to='/aiTradingPlan'>AI Trading Plan</Link></li>
                            <li><Link to='/aiMiningPlan'>AI Mining Plan</Link></li>
                            <li><Link to='/investAndLoan'>Invest and Loan</Link></li>
                            <li><Link to='/'>GeoDB Coin</Link></li>
                            <li>
                                <Link 
                                    to={isLoggedIn ? '/user/dashboard' : '/login'}   
                                    style={{color:'#A700FA'}}
                                    >
                                    {avatar}
                                </Link>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}
export default Header;