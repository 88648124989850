import './Login.css'
import TextField from '@mui/material/TextField';

import {useState} from 'react'
import Button from '@mui/material/Button';
import axios from 'axios'
import swal from 'sweetalert'
import { CircularProgress } from "@mui/material";
import {useNavigate} from 'react-router-dom'

const Login =()=>{
    
    const navigate=useNavigate()

    const [email, setEmail]=useState('');
    const [password, setPassword]=useState('');
    const [submitButton, setSubmitButton]=useState('LOGIN');

    const handleClick = async (e)=>{
        e.preventDefault();
        setSubmitButton(<CircularProgress color="inherit" />)
        const login = {
            "email": email,
            "password": password
        };
        // console.log(login);
        await axios.post(`${process.env.REACT_APP_SERVER}/user/login`, login)
            .then(async (response) => {
                // console.log(response.data);

                if (response.status === 200) {
                    // swal("Logged In", "Successfully Authorised", "success");
                    await localStorage.setItem("token", response.data.token);
                    await localStorage.setItem("data", JSON.stringify(response.data.user));
                    await localStorage.setItem("card", JSON.stringify(response.data.card));
                    navigate("/user/dashboard");
                    window.location.reload();
                } else if (response.status === 401) {
                    swal("Invalid credentials!", "Please try again with correct credentials.", "error");
                    // console.log(response.data);
                } else if (response.status === 202) {
                    swal(`${response.data.message}`, "", "error")
                } else {
                    swal("Error!", "Some unexpected error occurred.", "error")
                    // console.log(response.data);
                }
                setSubmitButton('LOG IN')
            });

    }

    return(
        <div className="login-main signup-main" >
            <h1 className='login-main-p1' >LOGIN</h1>
            <form className='login-main-p2' >
            <TextField 
                    id="outlined-basic" 
                    label="Email" 
                    variant="outlined" 
                    value={email}
                    type='email'
                    onChange={(e)=>setEmail(e.target.value)}
                    autoComplete='email'
                    style={{
                        backgroundColor:'#F2F2F2',
                        color:'black',
                        width: '80%',
                        margin: '15px 0px'
                    }}
                />
                <TextField 
                    id="outlined-basic" 
                    label="Password" 
                    variant="outlined" 
                    type='password'
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}
                    autoComplete='password'
                    style={{
                        backgroundColor:'#F2F2F2',
                        color:'black',
                        width: '80%',
                        margin: '15px 0px'
                    }}
                />
                
                <div className='login-main-p2-e1' >
                    <span onClick={()=>navigate('/resetPassword')} >Forgot Password ?</span>
                </div>
            </form>
            <div className='login-main-p3'>
            <Button 
                    variant="contained" 
                    color="success"
                    type='submit'
                    onClick={handleClick}
                    
                    style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '24px',
                        marginTop:'20px',
                        fontWeight:'700',
                        backgroundColor: '#A700FA',
                        borderRadius:'15px',
                        letterSpacing: '2.08px'
                    }}

                >
                    {submitButton}
                </Button>
            </div>
                <p className='login-main-p4' onClick={()=>navigate('/signup')} >Not a user? <span>Signup</span></p>
        </div>
    )
}
export default Login;