import { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { TextareaAutosize } from '@mui/base';
import {CircularProgress} from '@mui/material'
import Swal from 'sweetalert2'
import {useNavigate} from 'react-router-dom'

import axios from 'axios'
import config from '../../config/default';

const Report = () => {
    const navigate=useNavigate();
    const user=JSON.parse(localStorage.getItem('data'))

    const [email, setEmail] = useState(user.email)
    const [reportEmail, setReportEmail]=useState()
    const [reportReason, setReportReason]=useState()
    const [reportAttachment, setReportAttachment]=useState()
    const [submitButton, setSubmitButton]=useState('SUBMIT')

    
    const handleClick = async (e) => {
        e.preventDefault();

        const data=new FormData()

        data.append('reportedBy', email);
        data.append('reportedAccount', reportEmail);
        data.append('reportReason', reportReason);
        data.append('image', reportAttachment);

        setSubmitButton(<CircularProgress color="inherit" />);

        await axios.post(`${config.server.link}/user/report`, data,
            {
                headers: {
                    "Authorization": `Bearer ${localStorage.getItem("token")}`
                }
            })
            .then((response) => {
                setSubmitButton("Submit")
                if (response.status === 200) {
                    Swal.fire({title: `${response.data.message}`, icon: "success"})
                        .then(() => {
                            navigate("/user/dashboard")
                        })
                } else if (response.status === 202) {
                    Swal.fire({title: `${response.data.message}`, icon: "error"})
                }
            })

    }


    return(
         
        <div className="login-main signup-main " >

            <h1 className='login-main-p1' >REPORT</h1>   
            <form  className='login-main-p2' >
                <TextField
                    id="outlined-basic" 
                    label="Your Email" 
                    variant="outlined" 
                    disabled
                    value={email}
                    type='email'
                    onChange={(e)=>setEmail(e.target.value)}
                    autoComplete='email'
                    style={{
                        backgroundColor:'#F2F2F2',
                        color: 'black',
                        width: '80%',
                        margin: '15px 0px',
                        zIndex: '0'
                    }}
                />
                <TextField
                    id="outlined-basic" 
                    label="Enter email you want to report" 
                    variant="outlined" 
                    value={reportEmail}
                    type='email'
                    onChange={(e)=>setReportEmail(e.target.value)}
                    autoComplete='email'
                    style={{
                        backgroundColor:'#F2F2F2',
                        color: 'black',
                        width: '80%',
                        margin: '15px 0px',
                        zIndex: '0'
                    }}
                />
                
                <TextareaAutosize
                    label="Description" 
                    variant="outlined" 
                    type='text'
                    value={reportReason}
                    onChangeCapture={(e)=>setReportReason(e.target.value)}
                    placeholder='Description'
                    style={{
                        backgroundColor:'#E3E3F6',
                        color: 'black',
                        width: '77%',
                        minWidth: '47.8%',
                        maxWidth: '80%',
                        minHeight:'50px',
                        margin: '15px 0px',
                        borderRadius: '5px',
                        borderColor: '#BABABA',
                        padding: '16.5px 14px',
                        fontFamily: 'Poppins'
                        
                    }}
                />
                <TextField
                    id="outlined-basic" 
                    variant="outlined" 
                    type='file'
                    onChange={(e)=>setReportAttachment(e.target.files[0])}
                    inputProps={{
                        accept: 'image/*'
                    }}
                    style={{
                        backgroundColor:'#F2F2F2',
                        width: '80%',
                        margin: '15px 0px'
                    }}
                />

            </form>
            <div className='login-main-p3' >
                <Button 
                    variant="contained" 
                    color="success"
                    type='submit'
                    onClick={handleClick}
                    style={{
                        width: '100%',
                        padding: '10px',
                        fontSize: '24px',
                        marginTop:'20px',
                        fontWeight:'700',
                        backgroundColor: '#A700FA',
                        borderRadius:'15px',
                        letterSpacing: '2.08px'
                    }}

                >
                    {submitButton}
                </Button>
            </div>
        </div>
    
    )
}
export default Report