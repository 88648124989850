import { useEffect } from "react";



const loadWidgetScript = () => {
    const script = document.createElement("script");
    script.src='https://geodbcoin-main.s3.eu-north-1.amazonaws.com/script/LiveCoinWatchScript.js'
    script.async=true;
    script.defer=true;

    document.body.appendChild(script);
}
const CoinWidget = (props) =>{
    useEffect(()=>{
        loadWidgetScript();
    }, [])
    return(
        <div 
            class="livecoinwatch-widget-6" 
            lcw-coin={props.coin} 
            lcw-base="USD" 
            lcw-period="d" 
            lcw-color-tx="#000000" 
            lcw-color-bg="#ffffff" 
            lcw-border-w="0" 
            style={{pointerEvents: "none"}}
        ></div>
    )
}
export default CoinWidget;