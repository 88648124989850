import TextField from '@mui/material/TextField';

import {useState} from 'react'
import Button from '@mui/material/Button';
import axios from 'axios'
import Swal from 'sweetalert2'
import { CircularProgress } from "@mui/material";
import {useNavigate} from 'react-router-dom'
import config from '../../config/default'

const ResetPassword =()=>{
    
    const navigate=useNavigate()

    const [email, setEmail]=useState('');
    const [otp, setOtp]=useState('');
    const [password, setPassword]=useState('');
    const [cPassword, setCPassword]=useState('');


    const [sendOtpButton, setSendOtpButton]=useState('Send OTP')    
    const [isOtpSent, setIsOtpSent]=useState(false);
    const [changePasswordButton, setChangePasswordButton]=useState('Change Password');

    const handleSendOtp= async(e)=>{
        e.preventDefault();
        setSendOtpButton(<CircularProgress color='inherit' />)
        if(email===''){
            Swal.fire({
                title: "Invalid Email!",
                icon: "error"
              });
              setSendOtpButton('Send OTP')
              return;
        }

        const data={email: email}
        await axios.post(`${config.server.link}/user/sendUserPasswordResetOtp`, data)
                .then(async (response)=>{
                    if(response.status === 200){
                        setSendOtpButton('OTP sent!')
                        setIsOtpSent(true)
                        await sessionStorage.setItem('otpId', response.data.userId)
                    }
                    if(response.status===202){
                        Swal.fire({
                            title:'User is not registered!',
                            icon:'warning',
                            confirmButtonText:'SIGNUP'
                        }).then((result=>{
                            result.isConfirmed && navigate('/signup')
                        }))
                        
                    }
                    
                })
    }
    const handleChangePassword=async(e)=>{
        e.preventDefault();
        setChangePasswordButton(<CircularProgress color='inherit' />)

        const data={
            password:password,
            cPassword:cPassword,
            otpId:sessionStorage.getItem('otpId'),
            otp:otp
        }

        await axios.post(`${config.server.link}/user/changePassword`, data,
            {
                headers:{
                    'Authorization': `Bearer ${sessionStorage.getItem('otpId')}`
                }
            })
            .then((response) => {
                setChangePasswordButton("Change Password")
                if (response.status === 200) {

                    Swal.fire({text:`${response.data.message}`, icon: "success"})
                        .then(() => {
                            navigate("/login")
                        })
                } else if (response.status === 202) {
                    Swal.fire({text:`${response.data.message}`,icon: "error"})

                }
            })

    }



    
    return(
        <div className="login-main signup-main" >
            <h1 className='login-main-p1' >RESET PASSWORD</h1>
            <form className='login-main-p2' >
                <TextField 
                        id="outlined-basic" 
                        label="Email" 
                        variant="outlined" 
                        value={email}
                        type='email'
                        onChange={(e)=>{setEmail(e.target.value); setIsOtpSent(false)}}
                        autoComplete='email'
                        style={{
                            backgroundColor:'#F2F2F2',
                            color:'black',
                            width: '80%',
                            margin: '15px 0px 0px 0px'
                        }}
                />
                <Button 
                    variant="contained" 
                    // color='success'
                    disabled={isOtpSent && true} 
                    type='submit'
                    onClick={handleSendOtp}
                    
                    style={{
                        width: '80%',
                        padding: '5px',
                        fontSize: '18px',
                        marginTop:'5px',
                        color:'white',
                        // fontWeight:'700',
                        backgroundColor: isOtpSent ? 'green' : '#A700FA',
                        borderRadius:'5px',
                        letterSpacing: '2.08px'
                    }}

                >
                    {sendOtpButton}
            </Button>


            <br/>
            <br/>


            {isOtpSent && 
                <>
                    <TextField 
                        id="outlined-basic" 
                        label="Enter OTP" 
                        variant="outlined" 
                        type='number' 
                        value={otp}
                        onChange={(e)=>setOtp(e.target.value)}
                        autoComplete='otp'
                        style={{
                            backgroundColor:'#F2F2F2',
                            color:'black',
                            width: '80%',
                            margin: '15px 0px 0px 0px'
                        }}
                    />
                    <TextField 
                        id="outlined-basic" 
                        label="New Password" 
                        variant="outlined" 
                        type='password' 
                        value={password}
                        onChange={(e)=>setPassword(e.target.value)}
                        autoComplete='password'
                        style={{
                            backgroundColor:'#F2F2F2',
                            color:'black',
                            width: '80%',
                            margin: '15px 0px 0px 0px'
                        }}
                    />
                    <TextField 
                        id="outlined-basic" 
                        label="Confirm Password" 
                        variant="outlined" 
                        type='password' 
                        value={cPassword}
                        onChange={(e)=>setCPassword(e.target.value)}
                        autoComplete='password'
                        style={{
                            backgroundColor:'#F2F2F2',
                            color:'black',
                            width: '80%',
                            margin: '5px 0px 0px 0px'
                        }}
                    />
                    <Button 
                        variant="contained" 
                        color="success"
                        type='submit'
                        onClick={handleChangePassword}
                        
                        style={{
                            width: '80%',
                            padding: '5px',
                            fontSize: '18px',
                            marginTop:'5px',
                            // fontWeight:'700',
                            backgroundColor: '#A700FA',
                            borderRadius:'5px',
                            letterSpacing: '2.08px'
                        }}

                    >
                            {changePasswordButton}
                    </Button>
                </>
            
            }

            


            

            </form>
        </div>
    )
}
export default ResetPassword;