import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import ProtectedRoutes from './Utils/ProtectedRoutes';

import ProtectedRoutesForActivatedCard from './Utils/ProtectedRoutesForActivatedCard';
import {BlueBackground, WhiteBackground} from './Utils/ManageBackground';



//Importing Pages
import Header from './Components/Header/Header';
import HeaderAuth from './Components/HeaderAuth/HeaderAuth';

import Footer from './Components/Footer/Footer';
import ReferralPage from './Pages/ReferralPage/ReferralPage'
import Home from './Pages/Home/Home';
import MembershipPlan from './Pages/MembershipPlan/MembershipPlan';
import AITradingPlan from './Pages/AITradingPlan/AITradingPlan';
import AIMiningPlan from './Pages/AIMiningPlan/AIMiningPlan';
import InvestAndLoan from './Pages/InvestAndLoan/InvestAndLoan';

import Dashboard from './Pages/Dashboard/Dashboard';
import ActivateDebitCard from './Pages/ActivateDebitCard/ActivateDebitCard';
import MerchantVisaCardDetails from './Pages/CardDetails/MerchantVisaCard_Details';
import ClassicVisaCardDetails from './Pages/CardDetails/ClassicVisaCard';
import PrimeVisaCardDetails from './Pages/CardDetails/PrimeVisaCard';
import PlatinumVisaCardDetails from './Pages/CardDetails/PlatinumVisaCard';
import WorldEliteVisaCardDetails from './Pages/CardDetails/WorldEliteVisaCard';
import BankWithdrawal from './Pages/BankWithdrawal/BankWithdrawal';

import PaymentOption from './Pages/PaymentOption/PaymentOption';

import BitcoinQR from './Pages/QRPages/Bitcoin_QR';
import EthereumQR from './Pages/QRPages/Ethereum_QR';
import BinanceQR from './Pages/QRPages/Binance_QR';
import TetherQR from './Pages/QRPages/Tether_QR';
import SolanaQR from './Pages/QRPages/Solana_QR';


import Signup from './Pages/Signup/Signup';
import Login from './Pages/Login/Login'


import BtcFundTransfer from './Pages/FundTransfer/BtcFundTransfer';
import EthFundTransfer from './Pages/FundTransfer/EthFundTransfer';
import BnbFundTransfer from './Pages/FundTransfer/BnbFundTransfer';
import UsdtFundTransfer from './Pages/FundTransfer/UsdtFundTransfer';
import SolFundTransfer from './Pages/FundTransfer/SolFundTransfer';
import BgeoFundTransfer from './Pages/FundTransfer/BgeoFundTransfer';


import BtcFundTransferMain from './Pages/FundTransferMain/BtcFundTransferMain';
import EthFundTransferMain from './Pages/FundTransferMain/EthFundTransferMain';
import BnbFundTransferMain from './Pages/FundTransferMain/BnbFundTransferMain';
import UsdtFundTransferMain from './Pages/FundTransferMain/UsdtFundTransferMain';
import SolFundTransferMain from './Pages/FundTransferMain/SolFundTransferMain';
import BgeoFundTransferMain from './Pages/FundTransferMain/BgeoFundTransferMain';


import ConnectTrustWallet from './Pages/ConnectTrustWallet/ConnectTrustWallet';

import Support from './Pages/Support/Support';
import Report from './Pages/Report/Report'
import ResetPassword from './Pages/ResetPassword/ResetPassword';
import VerifySignupOtp from './Pages/VerifySignupOtp/VerifySignupOtp';
import PlanComponent from './Pages/PlanComponent/PlanComponent';



const App=()=>{


  return(
    <div style={{backgroundColor: '#EAF2FF', padding: '45px 50px'}} id='app'>
      <Router>
        <Routes>
          {/* Non-protected routes */}
          <Route 
            path='/*'
            element={
              <>
                <BlueBackground/>
                {localStorage.getItem('token') ? <HeaderAuth/> : <Header/>}
                <Routes>

                  {/* Managing the undefined routes */}
                  <Route path='*' element={<Navigate to='/' />} />
                
                  <Route element={<Home/>} path='/' />
                  <Route element={<MembershipPlan/>} path='/membershipPlan' />
                  <Route element={<AITradingPlan/>} path='/aiTradingPlan' />
                  <Route element={<AIMiningPlan/>} path='/aiMiningPlan' />
                  <Route element={<InvestAndLoan/>} path='/investAndLoan' />
                  <Route element={<Signup />} path='/signup' />
                  <Route element={<VerifySignupOtp />} path='/signup/verify' />
                  <Route element={<Login />} path='/login' />
                  <Route element={<ResetPassword />} path='/resetPassword' />
                </Routes>
                <Footer/>
              </>
            } 

          />
          


          {/* Protected Routes */}
          <Route
            path='/user/*'  //Routes will start with /user/then_the_routes_given_below
            element={
              <>
                <WhiteBackground/>
                <HeaderAuth/>
                <Routes>
                  <Route element={<ProtectedRoutes/>}> {/* Protected */}

                    {/*Managing the undefined routes */}
                    <Route path='*' element={<Navigate to='/' />} />

                    <Route element={<Dashboard/>} path='/dashboard' />
                    <Route element={<ActivateDebitCard/>} path='/activateDebitCard' />

                    <Route element={<MerchantVisaCardDetails/>} path='/cardDetails/merchant' />
                    <Route element={<ClassicVisaCardDetails/>} path='/cardDetails/classic' />
                    <Route element={<PrimeVisaCardDetails/>} path='/cardDetails/prime' />
                    <Route element={<PlatinumVisaCardDetails/>} path='/cardDetails/platinum' />
                    <Route element={<WorldEliteVisaCardDetails/>} path='/cardDetails/worldElite' />
                    <Route element={<BankWithdrawal/>} path='/withdrawal/bank' />
                    
                    <Route element={<PlanComponent/>} path='/plan/:planName' />
                    <Route element={<PaymentOption/>} path='/paymentOption' />

                    <Route element={<BitcoinQR/>} path='/paymentOption/bitcoin' />
                    <Route element={<EthereumQR/>} path='/paymentOption/ethereum' />
                    <Route element={<BinanceQR/>} path='/paymentOption/binance' />
                    <Route element={<TetherQR/>} path='/paymentOption/tether' />
                    <Route element={<SolanaQR/>} path='/paymentOption/solana' />


                    <Route element={<BtcFundTransfer/>} path='/fundtransfer/btc' />
                    <Route element={<EthFundTransfer/>} path='/fundtransfer/eth' />
                    <Route element={<BnbFundTransfer/>} path='/fundtransfer/bnb' />
                    <Route element={<UsdtFundTransfer/>} path='/fundtransfer/usdt' />
                    <Route element={<SolFundTransfer/>} path='/fundtransfer/sol' />

                    <Route element={<BtcFundTransferMain/>} path='/fundTransfer/btc/main' />
                    <Route element={<EthFundTransferMain/>} path='/fundTransfer/eth/main' />
                    <Route element={<BnbFundTransferMain/>} path='/fundTransfer/bnb/main' />
                    <Route element={<UsdtFundTransferMain/>} path='/fundTransfer/usdt/main' />
                    <Route element={<SolFundTransferMain/>} path='/fundTransfer/sol/main' />


                    <Route element={<ConnectTrustWallet/>} path='/connectTrustWallet'  />


                    <Route element={<ReferralPage/>} path='/referralPage' />
                    <Route element={<Support/>} path='/support' />
                    <Route element={<Report/>} path='/report' />
                  </Route>





                  <Route element={<ProtectedRoutesForActivatedCard/>}> {/* Protected for Activated Card*/}
                    <Route element={<BgeoFundTransfer/>} path='/fundtransfer/bgeo' />
                    <Route element={<BgeoFundTransferMain/>} path='/fundTransfer/bgeo/main' />
                  </Route>
                </Routes>
              </>
            }
          />
          

        </Routes>
      </Router>
    </div>
  )
}
export default App;