import './Transaction.css'
import {useEffect} from 'react'

const Transaction = ({isCredit, isDebit, address, amount, currency})=>{

    useEffect(()=>{
        //FontAwesomr Setup
        const script=document.createElement('script');
        script.src='https://kit.fontawesome.com/e836cc5221.js'
        script.crossOrigin='anonymous'
        document.head.appendChild(script)
    }, [])

    return(
        <div className='transaction-main' >
            <div className='transaction-main-r1' >
                <div className='transaction-main-r1-c1' >
                    {
                        isCredit 
                            ? <i class="fa-solid fa-circle-arrow-down fa-2xl" style={{color: '#818181'}}></i>
                            : <i class="fa-solid fa-circle-arrow-up fa-2xl" style={{color: '#818181'}}></i>
                    }
                    
                </div>
                <div className='transaction-main-r1-c2' >
                    <span>Transfer</span>
                    <p>{(isCredit && 'From')  || (isDebit && 'To')}: {address==='admin' ? 'GeoDB Deaprtment' : address}</p>
                </div>
            </div>
            <div className='transaction-main-r2' style={{color: (isCredit&&'#53D396') || (isDebit&&'#F80F0F') }} >
                {isCredit ? '+' : '-'} {amount} {String(currency).toUpperCase()}
            </div>
        </div>
    )
}
export default Transaction;