import {Link,useNavigate} from 'react-router-dom'
import './HeaderAuth.css'; //Most of the CSS in used from Header.css file.

import Logo from '../../Assets/HomeAssets/Logo.png'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';


const closeSlider=()=>{
    document.getElementById('menuToggleCheckbox').checked = false;
}
const HeaderAuth = ()=>{

    const navigate=useNavigate();
    return(
        <div className='header-main headerAuth-main ' >
            <div className="header-left" >
                <img 
                    src={Logo} 
                    alt='Logo' 
                    loading='lazy' 
                    onClick={()=>localStorage.getItem('token') ? navigate('/user/dashboard') : navigate('/')}
                />
            </div>


            <div className="header-right navigation-large headerAuth-navigation-large" >
                <Link to='/'>Home</Link>
                <Link to='/'>Services</Link>
                <Link to='/membershipPlan'>Membership Plan</Link>
                <Link to='/aiTradingPlan'>AI Trading Plan</Link>
                <Link to='/aiMiningPlan'>AI Mining Plan</Link>
                <Link to='/investAndLoan'>Invest and Loan</Link>
                <Link to='/'>GeoDB Coin</Link>
                <Link to='/user/dashboard' style={{color:'#A700FA'}}> <AccountCircleIcon/> Account</Link>
            </div>


            <div className='navigation-small headerAuth-navigation-small' >
                <nav class="menu--right" role="navigation">
                    <div class="menuToggle">
                        <input type="checkbox" id='menuToggleCheckbox'/>
                        <span></span>
                        <span></span>
                        <span></span>
                        <ul class="menuItem">
                            <li onClick={closeSlider}><Link to='/'>Home</Link></li>
                            <li onClick={closeSlider}><Link to='/user/dashboard'>Dashboard</Link></li>
                            <li onClick={closeSlider}><Link to='/user/activateDebitCard'>Activate Debit Card</Link></li>
                            <li className='menuItem-sub1-controller'><Link>Membership and Investment Plan</Link>
                                <ul className='menuItem-sub1' >
                                    <li onClick={closeSlider}><Link to='/membershipPlan'>○ Membership Plan</Link></li>
                                    <li onClick={closeSlider}><Link to='/aiTradingPlan'>○ AI Trading Plan</Link></li>
                                    <li onClick={closeSlider}><Link to='/aiMiningPlan'>○ AI Mining Plan</Link></li>
                                    <li onClick={closeSlider}><Link to='/investAndLoan'>○ Invest and Loan</Link></li>
                                </ul>
                            </li>
                            {/* <li><Link to='/user/withdrawal/bank'>Bank Withdrawal</Link></li> */}
                            <li onClick={closeSlider}><Link to='/user/fundTransfer/btc/main'>Bitcoin Withdrawal</Link></li>
                            <li onClick={closeSlider}><Link to='/user/fundTransfer/usdt/main'>USDT Withdrawal</Link></li>
                            <li onClick={closeSlider}><Link to='/user/connectTrustWallet'>Connect Trust Wallet</Link></li>
                            <li onClick={closeSlider}><Link to='/user/support'>Support</Link></li>
                            <li onClick={closeSlider}><Link to='/user/report'>Report</Link></li>
                            <li onClick={closeSlider}><Link to='/user/referralPage'>Referral Link</Link></li>
                            <li onClick={closeSlider}><Link to='/' onClick={()=>{localStorage.clear();window.location.reload()}} >Log out</Link></li>
                        </ul>
                    </div>
                </nav>
            </div>
        </div>
    )
}
export default HeaderAuth;