import Card3 from "../../Components/Card3/Card3";


const PrimeVisaCard_Details=()=>{
    return(
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'white',
            margin: '50px -50px',
            // marginLeft:'-50px',
            // marginRight:'-50px'
        }}>
            <Card3 head='Prime Visa Card' />
        </div>
    )
}
export default PrimeVisaCard_Details;