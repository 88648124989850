import './ClaimNowButton.css'


const ClaimNowButton = ({onClickFunction}) =>{
    return(
        <div className='claimNowButton-main' onClick={onClickFunction} >
            <span>CLAIM NOW</span>
        </div>
    )
}
export default ClaimNowButton;