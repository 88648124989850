import './JoinUs.css'
import JoinUsImage from '../../../Assets/HomeAssets/JoinUs.png';

const JoinUs = () => {
    return(
        <div className="joinUs-main" >
            <div className='joinUs-main-a home-main-c1-head' >Come Join Us!</div>
            <div className="joinUs-main-b home-main-c1-head" >Great People Take <span>Great Decisions</span></div>
            <div className='joinUs-main-b home-main-c1-head' >By Investing Through Us.</div>
            <div className='joinUs-main-c' >
                <button 
                    onClick={()=>{

                        window.scrollTo({
                            top: 2000,
                            behavior: 'smooth'
                        })
                    }} 
                >See Services</button>
            </div>
            <div className='joinUs-main-d' ><img src={JoinUsImage} alt='JoinUs' /></div>
        </div>
    )
}
export default JoinUs;