import * as React from 'react';
import {useEffect, useState} from 'react'
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#D13070',
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#E9F2FF',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(from, action, amount, date, remark) {
  return { from, action, amount, date, remark };
}



const TransactionTable=({transactionArray})=> {


    const [rows, setRows] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const updatedRows = await Promise.all(
                transactionArray
                    // .filter(element => element.toType === 'btc')
                    .map(async element => {
                        return createData(element.rcpt, element.action, element.amount, element.date, element.remark);
                    })
            );
            setRows(updatedRows);
        };

        fetchData();
    // eslint-disable-next-line
    }, []);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>To / From</StyledTableCell>
            <StyledTableCell align="right">Credit / Debit</StyledTableCell>
            <StyledTableCell align="right">Amount</StyledTableCell>
            <StyledTableCell align="right">Time</StyledTableCell>
            <StyledTableCell align="right">Remark</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                {row.from}
              </StyledTableCell>
              <StyledTableCell align="right" style={{color: row.action==='credit' ? 'green' : 'red'}} >{String(row.action).toUpperCase()}</StyledTableCell>
              <StyledTableCell align="right">{row.amount}</StyledTableCell>
              <StyledTableCell align="right">{row.date}</StyledTableCell>
              <StyledTableCell align="right">{row.remark}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
        
      </Table>
    </TableContainer>
  );
}


export default TransactionTable;