import './ReferralPage.css'
import { useState, useEffect } from 'react';

import config from '../../config/default'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import toast from 'react-hot-toast';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import updateLocalData from '../../Utils/UpdateLocalData'

const ReferralPage = () => {
    
    // eslint-disable-next-line
    const [referralCount, setReferralCount]=useState()
    const [referralCode, setReferralCode]=useState();
    const [referralLink, setReferralLink] = useState()
    
    const notify = () => toast.success('Link copied to clipboard!');

    useEffect(()=>{
        updateLocalData();
        setReferralCount(JSON.parse(localStorage.getItem('data')).referral.referralCount)
        setReferralCode(JSON.parse(localStorage.getItem('data')).referral.referralCode)
        setReferralLink(`${config.client.link}/signup?referralCode=${referralCode}`)
    },[referralCode])

    return(
        <div className='referralPage-main' >

            
            <div className='referralPage-main-content' >
                <h2>REFER AND EARN</h2>

                <div className='referralPage-main-content-container1' >
                    <h4>Refer Friends and win</h4>
                    <p>PER USER</p>
                    <span>$100 AIRDROP</span>

                </div>

                <div className='referralPage-main-content-container2'>
                    <h2>Your Code</h2>
                    <div className='referralPage-main-content-container2-link' >
                        <span style={{marginRight: '15px'}} >{referralLink}</span>
                        <CopyToClipboard text={referralLink}>
                            <ContentCopyIcon onClick={notify} />
                        </CopyToClipboard></div>
                    </div>
                
                


                {/* <div>
                    Total successfull referrals: {referralCount}
                </div>  */}
            </div>

            

            
            
        </div>
    )
}

export default ReferralPage;