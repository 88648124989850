import {useEffect} from 'react'
import AOS from 'aos'

import {useNavigate} from 'react-router-dom'

import './ConnectWallet.css';
import WalletConnect from '../../Assets/ConnectWallet/ConnectWallet_Image2.png'
import TrustWallet from '../../Assets/ConnectWallet/ConnectWallet_Image1.png';

const ConnectWallet = ()=>{
    const navigate=useNavigate()

    useEffect(()=>{
        AOS.init({
            once: true,
            // disable: "phone",
            duration: 1000,
            easing: "ease-out-cubic",
        });
    },[])



    const handleClick = () => {
        navigate('/user/connectTrustWallet')
    }
    return(
        <div className='connectWallet-main' >
            <div className='connectWallet-main-c1' data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="100" data-aos-offset="0" data-aos-duration="1500">
                Connect your <img src={TrustWallet} alt='TrustWallet' /> to get instant withdrawals and $100 Airdrop
            </div>
            <div className='connectWallet-main-c2' data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="100" data-aos-offset="0" data-aos-duration="2000">
                <button onClick={handleClick} ><img src={WalletConnect} alt='Wallet Connect' loading='lazy'/></button>
            </div>
        </div>
    )
}
export default ConnectWallet