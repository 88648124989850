import {useEffect} from 'react'
import './Card2.css'

import {Link} from 'react-router-dom'
import AOS from 'aos';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';

const Card2 = (props)=>{

    useEffect(()=>{
        AOS.init({
            once: true,
            // disable: "phone",
            duration: 1000,
            easing: "ease-out-cubic",
        });
    },[])
    return(
        <div className='card2-main' data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">
            <div className='card2-main-c1' >{props.head1}</div>
            <div className='card2-main-c2' ><img src={props.image} alt={props.head1} /></div>
            <div className='card2-main-c3' >{props.head2}</div>
            <div className='card2-main-c4' >{props.head3}</div>
            <div className='card2-main-c5' >
                <span><CheckBoxIcon/> {props.bodyL1}</span>
                <br/>
                <span><CardGiftcardIcon/> {props.bodyL2}</span>
                <br/>
                <span><ThumbUpOffAltIcon/> {props.bodyL3}</span>
            </div>
            <div className='card2-main-c6' ><Link to={props.buttonLink} ><button>Apply</button></Link></div>
        </div>
    )
}
export default Card2;