import {useEffect} from 'react'
import AOS from 'aos'

import './AITradingPlan.css'
import ConnectWallet from '../../Components/ConnectWallet/ConnectWallet';
import Card1 from '../../Components/Card1/Card1';

import Beginner from '../../Assets/AITradingPlan/AITradingPlan_Beginner.png'
import Advanced from '../../Assets/AITradingPlan/AITradingPlan_Advanced.png'
import Expert from '../../Assets/AITradingPlan/AITradingPlan_Expert.png'
import Premium from '../../Assets/AITradingPlan/AITradingPlan_Premium.png'

const AITradingPlan =() =>{
    

    useEffect(()=>{
        AOS.init({
            once: true,
            // disable: "phone",
            duration: 1000,
            easing: "ease-out-cubic",
        });
    },[])
  return (
    <div className="membership-main">

      <div className="membership-main-bg1 aiTrading-main-bg1" >
        <p className="membership-main-bg1-p1" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">AI Trading Plan</p>
        <p className="membership-main-bg1-p2" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">Trade using our AI Bots</p>
        <p className="membership-main-bg1-p3" data-aos="fade-right" data-aos-easing="linear" data-aos-duration="1500">
            Use our AI trading bots to automatically employ
            advanced algorithms, for precise, automated
            trading, performing efficient trades with deep
            market insights.
        </p>
      </div>

      <div className="membership-main-bg2 aiTrading-main-bg2">
        <h2 className="membership-main-bg2-p1 aiTrading-main-bg2-p1" data-aos="fade-zoom-in" data-aos-easing="ease-in-back" data-aos-delay="100" data-aos-offset="0" data-aos-duration="1500">Trading Plans</h2>
        <div className="membership-main-bg2-p2" >
          <Card1
            image={Beginner}
            head1="Beginner AI Trading Plan"
            head2="Trade Amount: $10-$99"
            bodyL1='> Profit 0.5% daily'
            bodyL2='> Get Airdrops Same Amount in your Trust Wallet (withdraw after 90 days) '
            bodyL3='> Withdrawal Time : monthly'
            bodyL4='> Free Maintenance and Chat Support'
            bodyL5='> Withdrawal Duration : 60 Minutes'
            buttonLink="/user/plan/Beginner AI Trading Plan"
          />
          <Card1
            image={Advanced}
            head1="Advanced Membeship Plan"
            head2="Trade Amount: $100-$999"
            bodyL1='> Profit 1% daily'
            bodyL2='> Get Airdrops Same Amount in your Trust Wallet (withdraw after 90 days) '
            bodyL3='> Withdrawal Time : weekly'
            bodyL4='> Free Maintenance and Chat Support'
            bodyL5='> Withdrawal Duration : 60 Minutes'
            buttonLink="/user/plan/Advanced Membeship Plan"
          />
          <Card1
            image={Expert}
            head1="Expert Membeship Plan"
            head2="Trade Amount: $1000-$9999"
            bodyL1='> Profit 2% daily'
            bodyL2='> Get Airdrops Same Amount in your Trust Wallet (withdraw after 90 days) '
            bodyL3='> Withdrawal Time : weekly'
            bodyL4='> Free Maintenance and Chat Support'
            bodyL5='> Withdrawal Duration : 60 Minutes'
            buttonLink="/user/plan/Expert Membeship Plan"
          />
          <Card1
            image={Premium}
            head1="Diamond Membeship Plan"
            head2="Trade Amount: $10,000-$100,000"
            bodyL1='> Profit 4% daily'
            bodyL2='> Get Airdrops Same Amount in your Trust Wallet (withdraw after 90 days) '
            bodyL3='> Withdrawal Time : monthly'
            bodyL4='> Free Maintenance and Chat Support'
            bodyL5='> Withdrawal Duration : 60 Minutes'
            buttonLink="/user/plan/Diamond Membeship Plan"
          />
        </div>
      </div>
      
      <ConnectWallet/>
    </div>
  );
}
export default AITradingPlan;